.active.datas {
  --tw-text-opacity: 1 !important;
  color: #8b5cf6 !important;
  color: rgb(139 92 246 / var(--tw-text-opacity)) !important;
}
.active.datas {
  background-color: #8b5cf61a !important;
}
.active.datas {
  --tw-text-opacity: 1 !important;
  color: #8b5cf6 !important;
  color: rgb(139 92 246 / var(--tw-text-opacity)) !important;
}
.font-large {
  font-size: 22px;
  margin-bottom: 20px;
}
.emt {
  margin-bottom: 10px;
  display: block;
}
.image-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 15px;
  gap: 25px;
  flex-wrap: wrap;
}
.image-row {
  justify-content: start;

  row-gap: 15px;
  column-gap: 15px;

  border-radius: 10px;
  display: flex;
  min-height: 80px;
  align-items: center;
}
.w-1001 {
  width: 100%;
}
.w-102 {
  width: 120px;
  display: inline;
}
.active.datas {
  background-color: #8b5cf61a !important;
}
.justify {
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.hideother:not(:first-child) {
  display: none;
}
.align-center {
  align-items: center;
}
.bg-danger-400a {
  background-color: rgb(244 63 94) !important;
  color: white !important;
}
/* Firefox */
.abspain {
  position: absolute;
  left: 20px;
  top: 13px;
  z-index: 58;
}
.flex-wraps {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  gap: 50px;
}
.fluxb li:hover {
  background-color: rgb(237 233 254);
  color: rgb(76 29 149);
}
.bolda {
  /* font-weight: bold; */
  font-size: 22px;
}
.bg-g {
  background-image: url("../../../assets/img/home-hero-gradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.react-responsive-modal-modal {
  border-radius: 20px;
  text-align: center;
}

.react-responsive-modal-modal .rounded2 {
  padding-top: 30px;
}
@media (max-width: 420px) {
  .verified-btn a,
  .verified-btn span,
  .verified-btn p {
    font-size: 11px;
  }
  .verified-btn {
    margin-inline-end: 0rem !important;
  }
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 14px;
}

input:checked + .slider:before {
  transform: translateX(22px);
}
.chkbx:disabled ~ .slider {
  opacity: 0.5 !important;
  cursor: default;
}
.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: -3px;
  bottom: -1.5px;
  background-color: rgb(234, 234, 234);
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #8b5cf6;
}
