.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: #3bb19b;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.verified {
  font-weight: bold;
  margin: 10px 0;
}
