.izTKCE {
  max-width: 100%;
  position: fixed;
  inset: 0px;
  z-index: 200;
}
.cVAYkx {
  height: 4.75rem;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 1rem;
  border-bottom: 1px solid rgb(223, 227, 235);
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 2;
}
.bZAztx {
  color: rgb(18, 21, 21);
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
}
.ldtbAq:not(:focus):not(:active) {
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.civDGE {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 10rem;
  background-color: rgb(247, 248, 250);
  padding-block-start: 4.75rem;
}
.frnRnh {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.CoYaV {
  width: 100%;
}
.dHaTIC {
  margin: 0.75rem 0.5rem 0px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  transition: background-color 300ms ease-out 0s;
  padding: 0.5rem;
}
.fGkhCw {
  background-color: rgb(240, 242, 247);
  color: rgb(18, 21, 21);
  border: none;
  outline: none;
  border-radius: 0.5rem;
  width: 100%;
  padding-inline-start: 0.75rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 12px;
  overflow: hidden;
}
.dKoxAc {
  background: none;
  border: none;
  padding: 0px;
  height: 1.5rem;
}
.dKoxAc {
  background: none;
  border: none;
  padding: 0px;
  height: 1.5rem;
}
.goCthX {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 1.25rem;
}
.dyFQsa {
  border: none;
  outline: none;
  background: transparent;
  padding: 0.625rem 0.75rem 0.625rem 0px;
  width: 100%;
}

.goCthX {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 1.25rem;
}

[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}
.hSkKYk {
  background-color: rgb(247, 248, 250);
  border-bottom: 1px solid rgb(240, 242, 247);
  padding: 2rem 1rem 1rem;
}
.flGaJY {
  color: rgb(103, 113, 133);
}

.bPSjlj {
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}
.iWYdFG {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem;
}

.gaEixf {
  width: 100%;
  border-bottom: 1px solid rgb(240, 242, 247);
  background-color: rgb(255, 255, 255);
}

.kmtyGJ {
  font-weight: 500;
}

.vvWMe {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.iWYdFG a {
  padding: 0px;
}

.kfOoaO {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem;
  color: rgb(18, 29, 51);
  transition: background-color 300ms ease 0s, color 300ms ease 0s;
}

.kmtyGJ {
  font-weight: 500;
}

.vvWMe {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.hYbymD {
  background-color: rgb(236, 245, 254);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 0.75rem;
}

.eRRaAq {
  margin-bottom: 0.25rem;
}

.kmtyGJ {
  font-weight: 500;
}

.vvWMe {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.hhsbKz {
  color: rgb(80, 89, 107);
}

.diDqUe {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.25rem;
}
@media (max-width: 600px) {
  .iDgAla {
    display: none !important;

    -webkit-box-align: center;
    align-items: center;
    padding: 0.5rem;
    height: min-content;
    border-radius: 0.5rem;
    border: none;
    background-color: rgb(240, 242, 247);
    box-sizing: border-box;
  }
}
.gaEixf {
  width: 100%;
  border-bottom: 1px solid rgb(240, 242, 247);
  background-color: rgb(255, 255, 255);
}

.kmtyGJ {
  font-weight: 500;
}

.vvWMe {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.kfOoaO {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem;
  color: rgb(18, 29, 51);
  transition: background-color 300ms ease 0s, color 300ms ease 0s;
}

.kmtyGJ {
  font-weight: 500;
}

.vvWMe {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.kLixvC {
  color: rgb(255, 255, 255);
  background-color: rgb(12, 108, 242);
  border-radius: 0.25rem;
  font-weight: 500;
  padding: 0.125rem 0.25rem;
  margin-left: 0.5rem;
  position: relative;
  top: -0.25rem;
  overflow-wrap: normal;
}

.gzboDI {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}
.dLGWtx {
  border-top: 1px solid rgb(223, 227, 235);
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(240, 242, 247) 0px -1px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px -0.25rem 0.875rem 0px;
}
.eCTxqO {
  margin-top: 1rem;
  display: flex;
}
.eCTxqO > * + :not(:last-child),
.eCTxqO > :first-child {
  margin-right: 1.25rem;
}

.bOFsqz {
  height: 44px;
}
.hiHwFx {
  font-size: 14px;
  border-color: rgb(12, 108, 242);
  width: 100%;
  height: 44px;
  padding: 10px 14px;
}

@media (max-width: 768px) {
  .bHMlWK {
    font-size: 0.875rem;
    line-height: 143%;
    padding: 0.625rem 0.875rem;
  }
}

.bHMlWK {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.75rem 1.375rem;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 144%;
  border-radius: 0.5rem;
  border: 0.125rem solid transparent;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  color: rgb(12, 108, 242);
  background: rgb(255, 255, 255);
}
.crgNqM {
  height: 44px;
}
.hUBKNX {
  background-color: rgb(239, 236, 254);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.fFxXYh {
  background-color: rgb(255, 242, 229);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.dVeqwX {
  background-color: rgb(230, 250, 236);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.maj {
  align-items: center;
  gap: 5px;
}
.fsm {
  margin-left: 10px;
  font-size: 12px;
}
.fsm1 {
  font-size: 14px;
}
.flexsa {
  display: flex;
  align-items: center;
  gap: 10px;
}
.toggle-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 100vh;
}
.mston {
  z-index: 999 !important;
}
.text-black {
  color: black !important;
}
.deHTay {
  background-color: rgb(240, 242, 247);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 0.75rem;
}

.eOOfRK {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.75rem 1.375rem;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 144%;
  border-radius: 0.5rem;
  border: 0.125rem solid transparent;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  background-color: rgb(12, 108, 242);
  color: rgb(255, 255, 255);
}
