.card-f {
  background-color: #1a1a1a !important;
  border-radius: 20px !important;
  margin-bottom: 30px;
}

.card-header:first-child {
  border-radius: 7px 7px 0 0;
}

.card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eff2f7;
  background: transparent;
  padding: 20px;
}
.card-title {
  font-size: 16px;
  margin-bottom: 0px;
  color: #273444;
  font-weight: 600;
}
.card .card-body {
  padding: 20px;
  background: transparent;
  border-radius: 15px;
}
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}
.form-label {
  color: white;

  margin-block: 0.5rem;
  display: block;
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: none;
  width: 100px;
}
.dtr select,
.dtr option {
  cursor: pointer !important;
}
.add,
.asd {
  width: calc(100% - 100px) !important;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.spinner-border-sm.borer {
  width: 2rem !important;
  height: 2rem !important;
  z-index: 9 !important;
  border-width: 0.3em !important;
  position: absolute !important;
  border-radius: 66px !important;
  left: 119px !important;

  animation: spinner-border 2.75s linear infinite !important;
  top: 9px !important;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  /* -webkit-box-flex: 1; */
  height: 50px;
  -ms-flex: none;
  flex: none;
  width: 1%;
  min-width: 0;
}
.form-control {
  border-radius: 5px;
  height: 45px;
  border: 1px solid #eff2f7;
  padding: 0px 22px;
  font-size: 14px;
  color: #273444;
  background: #fff;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8492a6;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
