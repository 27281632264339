.flexasa {
  display: flex;
  justify-content: start;
  text-align: left;
  color: rgb(200, 10, 10) !important;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}
.hry {
  font-size: 24px !important;
}
.bld {
  font-weight: bold;
}
.site-footer a {
  color: white !important;
}
.iframing {
  width: 100%;
  height: 200px;
}
.mxcon {
  width: max-content !important;
}

.add-btn {
  display: flex;
  justify-content: center;

  padding: 8px 15px;
  border-radius: 20px;
  align-items: center;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.8125rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(99, 102, 241, 0.5);
  color: rgb(99, 102, 241);
  border-radius: 12px;
  text-transform: none;
  padding: 6px 16px;
}

.imgtu {
  width: 200px;
}
.Link__CustomLink-sc-1p80yfz-0.active {
  background-color: #8b5cf6;
}
.ifnb {
  width: 100%;
  height: 400px;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
}
.ifnb.asmrej {
  width: 100%;
  height: 400px;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
}
.txt.sml {
  font-size: 13px;
}
.fulla {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-container {
  width: 100%;
  background-color: #555;
  height: 30px;
  border-radius: 30px;
  overflow: hidden;
}
.dss {
  border: 1px solid gray;
}

.progress-bar {
  height: 100%;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background-color: #8b5cf6;
}
.prf {
  padding-inline: 10px;
}
.flexas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
.asmrej {
  color-scheme: none !important;
}
.if-4 {
  border-radius: 20px !important;
}
.wci_overview_widget_div iframe {
  color-scheme: none !important;
}
.sdws {
  color-scheme: none !important;
  height: 496px;
}
.if-2 {
  width: 100%;
  border-radius: 0 !important;
  border: 0px solid black !important;
  height: 356px;
}
.cg-container {
  border-radius: 30px !important;
}
.wrapper-widget {
  background-color: black !important;
}
.line-bg {
  border: 1px solid gray;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 20px !important;
  padding: 1.5rem !important;
}
.line-bgasa {
  border: 1px solid gray;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 20px !important;
  padding: 20px !important;
}
.s-tb {
  width: 24%;
}
.line-22 {
  border: 1px solid gray;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 20px !important;
  padding: 20px !important;
}
.linec {
  row-gap: 20px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.line-bg-tb {
  width: max-content;
  /* border-radius: 20px !important; */
  padding: 0rem !important;
  /*  */
}
.logo-sec img {
  width: 40px;
}
.dvstyle {
  box-shadow: "5px 5px 10px rgba(0, 0, 0, 0.5)";
  display: "inline-block";
  width: 20px !important;
  box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;
}

.rn {
}
.logo-sec {
  display: flex;
  align-items: center;
  gap: 15px;
}
.logo-sec p {
  font-weight: bold;
  font-size: 19px;
}
.newtx:first-child {
  margin-top: 0px;
}
.newtx {
  color: white;
  display: flex;
  margin-block: 9.3px;
  align-items: center;
  justify-content: space-between;
}

.line-bg3 {
  border: 1px solid gray !important;

  background-color: rgb(0, 0, 0) !important;
  border-radius: 20px !important;
  padding: 1rem !important;
}

.poffers {
  text-align: center;
}
.poffers p {
  color: white;
}
.poffers h1 {
  font-size: 28px;
  color: white;
  font-weight: bold;
}

.poffers .img-ico svg path {
  fill: white;
}
.signlw {
  display: flex;
  align-items: center;
  gap: 9px;
  border: 1px solid white;
  padding: 5px 20px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
}
.flex-vts {
  display: flex;
  margin-top: 20px;

  justify-content: center;
  gap: 15px;
}
.custim {
  width: 100px !important;
}
.img-ico svg {
  width: 20px;
}
.poffers {
  padding-bottom: 30px;
}
.nma {
  font-weight: bold;
}
.htmData {
  border-radius: 20px;

  border: 1px solid gray;
  color: white;
  padding: 1.5rem;
  background-color: black;
}
.user-bg {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 13%,
    rgba(14, 23, 118, 1) 50%,
    rgba(2, 3, 41, 1) 72%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 13%,
    rgba(14, 23, 118, 1) 50%,
    rgba(2, 3, 41, 1) 72%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 13%,
    rgba(14, 23, 118, 1) 50%,
    rgba(2, 3, 41, 1) 72%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#020329",GradientType=1);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.anm {
  font-size: 30px;
}
.choti {
  font-size: 20px;
  color: gray;
}
.htmData a {
  color: #8b5cf6;
  text-decoration: underline;
}
.htmData ol {
  display: block !important;
  list-style-type: decimal !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 40px !important;
}
.ptbg {
  padding: 20px;
  border-radius: 20px;
  background-color: #1a1a1a;
}
.pt70 {
  padding-top: 80px;
}
.is-button {
  cursor: pointer;
}
.htmData ol li {
  list-style: decimal !important;
}
.htmData ul li {
  list-style: disc !important;
}
.htmData ul {
  display: block !important;
  list-style-type: disc !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 40px !important;
}
.inveret,
.htmData p,
.htmData h1,
.htmData h2,
.htmData h3,
.htmData h4,
.htmData h5,
.htmData h6 {
  font-size: revert !important;
  line-break: anywhere;
}
.htmlcode {
  margin-top: 20px;
}
.bg-lesf {
  background-color: rgba(0, 0, 0, 0.646);
}
.topb {
  border-radius: 20px 20px 0 0 !important;
}
.topb3 {
  border-radius: 20px !important;
}
.bob {
  border-radius: 0 0 20px 20px !important;
}

.line-bgb {
  background-color: black !important;
  padding: 20px !important;
}
.text-white {
  color: white;
}
.w-200 {
  width: 470px;
  margin: auto;
  margin-top: 15px;
}
.bg-black {
  background-color: black;
}
.wen {
  width: 120px !important;
  height: auto !important;
}
.text-underline {
  text-decoration: underline;
}
.is-button.wen {
  padding-block: 10px !important;
}
@media (max-width: 500px) {
  .ptbg {
    padding: 20px 10px;
  }
  .w-200 {
    width: 300px;
    margin: auto;
    margin-top: 15px;
  }
}
@media (max-width: 375px) {
  .flex-vts {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1450px) {
  .logo-sec {
    gap: 8px;
  }
  .logo-sec p {
    font-size: 16px;
  }
  .logo-sec img {
    width: 30px;
  }
  .price-sec {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .mb\:mx-auto {
    margin: auto !important;
  }
  .s-tb {
    width: 49%;
  }
  .line-22 {
    row-gap: 20px;
  }
  .fall {
    padding-inline: 1rem !important;
  }
}
@media (max-width: 850px) {
  .painwrap {
    flex-wrap: wrap;
    height: auto !important;
  }
  .address-line {
    width: 100%;
  }
  .nona {
    display: none;
  }
}
@media (max-width: 680px) {
  .painwrap {
    flex-direction: column;
    padding: 0 !important;
  }
  .nonanow {
    display: none;
  }
  .nowBlock {
    text-align: center;
    padding: 0 !important;
    display: block !important;
  }
}
@media (max-width: 768px) {
  .wci_overview_widget_div.line-bgs iframe,
  .line-22 {
    padding: 10px !important;
  }
  .line-bg {
    padding: 15px !important;
  }
}
@media (max-width: 524px) {
  .s-tb {
    width: 100%;
  }
  .line-22 {
    row-gap: 20px;
  }
  .chote {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;

    text-overflow: ellipsis;
  }
}
@media (max-width: 400px) {
  .nowBlock .text-sm {
    font-size: 12px !important;
  }
}
@media (max-width: 370px) {
  .nowBlock .text-sm {
    font-size: 12px !important;
  }
  .chote {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;

    text-overflow: ellipsis;
  }
}
body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--theme-palette-1: #09be8b;
  --wp--preset--color--theme-palette-2: #fb4e4e;
  --wp--preset--color--theme-palette-3: #ffffff;
  --wp--preset--color--theme-palette-4: #2d3748;
  --wp--preset--color--theme-palette-5: #cdcfd4;
  --wp--preset--color--theme-palette-6: #a6a6a6;
  --wp--preset--color--theme-palette-7: #104441;
  --wp--preset--color--theme-palette-8: #1e2738;
  --wp--preset--color--theme-palette-9: #141a28;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}
:where(.is-layout-flex) {
  gap: 0.5em;
}
body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body
  .is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}
body .is-layout-flex {
  display: flex;
}
body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}
body .is-layout-flex > * {
  margin: 0;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}

.bdt-mini-cart-wrapper .bdt-mini-cart-button-icon .bdt-cart-icon i {
  color: var(--global-palette2) !important;
}
@media (min-width: 1200px) {
  .container,
  .cryptozfree-container,
  .elementor-section.elementor-section-boxed:not(.menu__section)
    > .elementor-container {
    max-width: 1200px !important;
  }
}
.smooth-loader-wrapper .loader img {
  width: 60%;
}
.smooth-loader-wrapper {
  background-color: #1e2738;
}

#banner-breadcrumb::before {
  background-color: #f8f8f8;
}

.page-header.error-404-main,
.cryptozfree-banner-breadcrumb::before {
  background-image: linear-gradient(
    180deg,
    var(--global-palette1) 0%,
    var(--global-palette2) 100%
  ) !important;
  background-image: -webkit-linear-gradient(180deg, #a253d8 0%, #1545cb 100%);
}
.css-v57kt1 {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(var(--Grid-rowSpacing) / -2) calc(var(--Grid-columnSpacing) / -2);
}
.css-1m1aas1 {
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
  min-width: 0px;
  box-sizing: border-box;
  padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
}
.css-1gq5e6f {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgb(45, 55, 72);
  border-radius: 8px;
}
.css-4u2is6 {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}
.css-drtivy {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.css-4u2is6 > :not(style) ~ :not(style) {
  margin: 0px 0px 0px 16px;
}

.css-1msa3n8 {
  display: flex;
  flex-direction: column;
}
.css-15udru3 {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: rgb(160, 174, 192);
}

.css-1msa3n8 > :not(style) ~ :not(style) {
  margin: 4px 0px 0px;
}
.space-apas {
  justify-content: space-between;
  align-items: center;
}
.css-1oklce5 {
  margin: 0px;
  font-size: 1rem;
  line-height: 1.57;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 500;
}
.swapas {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 20px;
}
/*  */

/*  */
.custom-selectas {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.custom-selectas select {
  color: white !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font: inherit;
  border-radius: 8px;
  padding: 8px 7px !important; /* Adjust right padding for the arrow */
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  min-width: 0;
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background-color: #0b111f !important;
  box-sizing: content-box;
  background: url("../../assets/icons/drop-down-arrow.png") no-repeat right
    center; /* Replace 'arrow.png' with your arrow image */
  background-size: 16px; /* Adjust this value based on the size of your arrow image */
  -webkit-tap-highlight-color: transparent;
}

/* Hide the default arrow */
.custom-selectas select::-ms-expand {
  display: none;
}
.custom-selectas select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 14px; /* Adjust this value based on the width of the arrow */
  transform: translateY(-50%);
  pointer-events: none;
}

.swapas h1 {
  padding-block: 10px;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  width: -webkit-fill-available;
}
.swapas .activeas {
  background: #0b111f;
}
.min-aa {
  height: 100px !important;
}
.asSA:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.css-1msa3n8 > :not(style) ~ :not(style) {
  margin: 4px 0px 0px;
}
.flex-a {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.css-5d62nz {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: rgb(160, 174, 192);
}

.css-1s4gov3 {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 16px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  color: rgb(108, 115, 127);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.125rem;
  padding: 4px;
}
.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}
.cars .border-red-500 {
  border: 2px solid red !important;
}
.cars .text-red-500 {
  color: red !important;
}
@media (min-width: 600px) {
  .css-1m1aas1 {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-basis: auto;
    width: calc(100% * 6 / var(--Grid-columns));
  }
}

@media (min-width: 900px) {
  .css-1m1aas1 {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-basis: auto;
    width: calc(100% * 4 / var(--Grid-columns));
  }
}

.flex-re {
  display: flex;
  width: 100%;
}
.uppercase {
  text-transform: uppercase !important;
}
