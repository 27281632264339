.elementor-kit-5 {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-cryptozfree1: #09be8b;
  --e-global-color-cryptozfree2: #fb4e4e;
  --e-global-color-cryptozfree3: #ffffff;
  --e-global-color-cryptozfree4: #2d3748;
  --e-global-color-cryptozfree5: #cdcfd4;
  --e-global-color-cryptozfree6: #a6a6a6;
  --e-global-color-cryptozfree7: #104441;
  --e-global-color-cryptozfree8: #1e2738;
  --e-global-color-cryptozfree9: #141a28;
  --e-global-color-6d71536: #000000;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
}

.elementor-kit-5.el-is-editing {
  --global-palette1: #09be8b;
  --global-palette2: #fb4e4e;
  --global-palette3: #ffffff;
  --global-palette4: #2d3748;
  --global-palette5: #cdcfd4;
  --global-palette6: #a6a6a6;
  --global-palette7: #104441;
  --global-palette8: #1e2738;
  --global-palette9: #141a28;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.e-con {
  --container-max-width: 1140px;
}

.elementor-widget:not(:last-child) {
  margin-block-end: 20px;
}

.elementor-element {
  --widgets-spacing: 20px 20px;
}

h1.entry-title {
  display: var(--page-title-display);
}

@media (max-width: 1024px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }

  .e-con {
    --container-max-width: 1024px;
  }
}

@media (max-width: 767px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 767px;
  }

  .e-con {
    --container-max-width: 767px;
  }
}
.elementor-14
  .elementor-element.elementor-element-258cc4c:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-14
  .elementor-element.elementor-element-258cc4c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff00;
}

.elementor-14 .elementor-element.elementor-element-258cc4c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0px 10px 0px;
  z-index: 100;
}

.elementor-14
  .elementor-element.elementor-element-258cc4c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-14
  .elementor-element.elementor-element-184011d.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-184011d.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-184011d
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-14
  .elementor-element.elementor-element-3cf10ef
  > .elementor-widget-container {
  margin: 5px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-14
  .elementor-element.elementor-element-5ef790b.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-5ef790b.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-5ef790b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-14 .elementor-element.elementor-element-be2bc5f {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-14
  .elementor-element.elementor-element-22f8698.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-22f8698.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-22f8698
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-14
  .elementor-element.elementor-element-b066651.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-b066651.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-b066651.elementor-column
  > .elementor-widget-wrap {
  justify-content: flex-end;
}

.elementor-14
  .elementor-element.elementor-element-b066651
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-14
  .elementor-element.elementor-element-e977c45.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-e977c45.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-14
  .elementor-element.elementor-element-e977c45
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-14 .elementor-element.elementor-element-060ae60 .elementor-button {
  font-family: "Michroma", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  fill: var(--e-global-color-cryptozfree3);
  color: var(--e-global-color-cryptozfree3);
  background-color: var(--e-global-color-cryptozfree2);
}

@media (max-width: 1024px) {
  .elementor-14 .elementor-element.elementor-element-258cc4c {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 20px 20px 20px;
  }
}

@media (max-width: 767px) {
  .elementor-14 .elementor-element.elementor-element-258cc4c {
    padding: 20px 15px 20px 15px;
  }

  .elementor-14 .elementor-element.elementor-element-184011d {
    width: 42%;
  }

  .elementor-14 .elementor-element.elementor-element-5ef790b {
    width: 58%;
  }

  .elementor-14 .elementor-element.elementor-element-e977c45 {
    width: 42%;
  }
}

@media (min-width: 768px) {
  .elementor-14 .elementor-element.elementor-element-184011d {
    width: 15%;
  }

  .elementor-14 .elementor-element.elementor-element-5ef790b {
    width: 69.309%;
  }

  .elementor-14 .elementor-element.elementor-element-22f8698 {
    width: 97.646%;
  }

  .elementor-14 .elementor-element.elementor-element-b066651 {
    width: 2.304%;
  }

  .elementor-14 .elementor-element.elementor-element-e977c45 {
    width: 15%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-14 .elementor-element.elementor-element-184011d {
    width: 23%;
  }

  .elementor-14 .elementor-element.elementor-element-5ef790b {
    width: 50%;
  }

  .elementor-14 .elementor-element.elementor-element-22f8698 {
    width: 10%;
  }

  .elementor-14 .elementor-element.elementor-element-b066651 {
    width: 90%;
  }

  .elementor-14 .elementor-element.elementor-element-e977c45 {
    width: 25%;
  }
}
/*! elementor - v3.18.0 - 20-12-2023 */
.dialog-widget-content {
  background-color: var(--e-a-bg-default);
  position: absolute;
  border-radius: 3px;
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.dialog-message {
  line-height: 1.5;
  box-sizing: border-box;
}

.dialog-close-button {
  cursor: pointer;
  position: absolute;
  margin-top: 15px;
  right: 15px;
  color: var(--e-a-color-txt);
  font-size: 15px;
  line-height: 1;
  transition: var(--e-a-transition-hover);
}

.dialog-close-button:hover {
  color: var(--e-a-color-txt-hover);
}

.dialog-prevent-scroll {
  overflow: hidden;
  max-height: 100vh;
}

.dialog-type-lightbox {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.elementor-editor-active .elementor-popup-modal {
  background-color: initial;
}

.dialog-type-alert .dialog-widget-content,
.dialog-type-confirm .dialog-widget-content {
  margin: auto;
  width: 400px;
  padding: 20px;
}

.dialog-type-alert .dialog-header,
.dialog-type-confirm .dialog-header {
  font-size: 15px;
  font-weight: 500;
}

.dialog-type-alert .dialog-header::after,
.dialog-type-confirm .dialog-header::after {
  content: "";
  display: block;
  border-block-end: var(--e-a-border);
  padding-block-end: 10px;
  margin-block-end: 10px;
  margin-inline-start: -20px;
  margin-inline-end: -20px;
}

.dialog-type-alert .dialog-message,
.dialog-type-confirm .dialog-message {
  min-height: 50px;
}

.dialog-type-alert .dialog-buttons-wrapper,
.dialog-type-confirm .dialog-buttons-wrapper {
  padding-block-start: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 16px;
  outline: none;
  border: none;
  border-radius: var(--e-a-border-radius);
  background-color: var(--e-a-btn-bg);
  color: var(--e-a-btn-color-invert);
  transition: var(--e-a-transition-hover);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
  border: none;
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
  background-color: var(--e-a-btn-bg-hover);
  color: var(--e-a-btn-color-invert);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:active {
  background-color: var(--e-a-btn-bg-active);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:not([disabled]),
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:not([disabled]) {
  cursor: pointer;
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:disabled {
  background-color: var(--e-a-btn-bg-disabled);
  color: var(--e-a-btn-color-disabled);
}

.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button:not(.elementor-button-state)
  .elementor-state-icon,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button:not(.elementor-button-state)
  .elementor-state-icon {
  display: none;
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt {
  background: transparent;
  color: var(--e-a-color-txt);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover {
  background: var(--e-a-bg-hover);
  color: var(--e-a-color-txt-hover);
}

.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel:disabled,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled {
  background: transparent;
  color: var(--e-a-color-txt-disabled);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success {
  background-color: var(--e-a-btn-bg-success);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-success:hover {
  background-color: var(--e-a-btn-bg-success-hover);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary {
  background-color: var(--e-a-btn-bg-primary);
  color: var(--e-a-btn-color);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-take_over:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-take_over:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:hover {
  background-color: var(--e-a-btn-bg-primary-hover);
  color: var(--e-a-btn-color);
}

.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.e-btn-txt {
  background: transparent;
  color: var(--e-a-color-primary-bold);
}

.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.e-primary.e-btn-txt:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.e-primary.e-btn-txt:hover {
  background: var(--e-a-bg-primary);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro {
  background-color: var(--e-a-btn-bg-accent);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:hover {
  background-color: var(--e-a-btn-bg-accent-hover);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:active {
  background-color: var(--e-a-btn-bg-accent-active);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning {
  background-color: var(--e-a-btn-bg-warning);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-warning:hover {
  background-color: var(--e-a-btn-bg-warning-hover);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger {
  background-color: var(--e-a-btn-bg-danger);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger:focus,
.dialog-type-alert
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger:focus,
.dialog-type-confirm
  .dialog-buttons-wrapper
  .dialog-button.elementor-button-danger:hover {
  background-color: var(--e-a-btn-bg-danger-hover);
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button i,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button i {
  margin-inline-end: 5px;
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:visited,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:visited {
  color: initial;
}

.flatpickr-calendar {
  width: 280px;
}

.flatpickr-calendar .flatpickr-current-month span.cur-month {
  font-weight: 300;
}

.flatpickr-calendar .dayContainer {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}

.flatpickr-calendar .flatpickr-days {
  width: 280px;
}

.flatpickr-calendar .flatpickr-day {
  max-width: 37px;
  height: 37px;
  line-height: 37px;
}

.elementor-hidden {
  display: none;
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span,
.ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.elementor-clearfix::after {
  content: "";
  display: block;
  clear: both;
  width: 0;
  height: 0;
}

.e-logo-wrapper {
  background: var(--e-a-bg-logo);
  display: inline-block;
  padding: 0.75em;
  border-radius: 50%;
  line-height: 1;
}

.e-logo-wrapper i {
  color: var(--e-a-color-logo);
  font-size: 1em;
}

.elementor a {
  box-shadow: none;
  text-decoration: none;
}

.elementor hr {
  margin: 0;
  background-color: transparent;
}

.elementor img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor):not(
    .elementor-widget-theme-post-content
  )
  figure {
  margin: 0;
}

.elementor embed,
.elementor iframe,
.elementor object,
.elementor video {
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}

.elementor .elementor-background,
.elementor .elementor-background-holder,
.elementor .elementor-background-video-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  direction: ltr;
}

.elementor .elementor-background-video-container {
  transition: opacity 1s;
  pointer-events: none;
}

.elementor .elementor-background-video-container.elementor-loading {
  opacity: 0;
}

.elementor .elementor-background-video-embed {
  max-width: none;
}

.elementor .elementor-background-video,
.elementor .elementor-background-video-embed,
.elementor .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.elementor .elementor-background-video {
  max-width: none;
}

.elementor .elementor-html5-video {
  -o-object-fit: cover;
  object-fit: cover;
}

.elementor .elementor-background-overlay,
.elementor .elementor-background-slideshow {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.elementor .elementor-background-slideshow {
  z-index: 0;
}

.elementor .elementor-background-slideshow__slide__image {
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
}

.e-con-inner > .elementor-element.elementor-absolute,
.e-con > .elementor-element.elementor-absolute,
.elementor-widget-wrap > .elementor-element.elementor-absolute {
  position: absolute;
}

.e-con-inner > .elementor-element.elementor-fixed,
.e-con > .elementor-element.elementor-fixed,
.elementor-widget-wrap > .elementor-element.elementor-fixed {
  position: fixed;
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-auto,
.elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
  max-width: 100%;
}

@media (max-width: 1024px) {
  .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-auto,
  .elementor-widget-wrap
    .elementor-element.elementor-widget-tablet__width-initial {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-auto,
  .elementor-widget-wrap
    .elementor-element.elementor-widget-mobile__width-initial {
    max-width: 100%;
  }
}

.elementor-element {
  --flex-direction: initial;
  --flex-wrap: initial;
  --justify-content: initial;
  --align-items: initial;
  --align-content: initial;
  --gap: initial;
  --flex-basis: initial;
  --flex-grow: initial;
  --flex-shrink: initial;
  --order: initial;
  --align-self: initial;
  flex-basis: var(--flex-basis);
  flex-grow: var(--flex-grow);
  flex-shrink: var(--flex-shrink);
  order: var(--order);
  align-self: var(--align-self);
}

.elementor-element.elementor-absolute,
.elementor-element.elementor-fixed {
  z-index: 1;
}

.elementor-element:where(.e-con-full, .elementor-widget) {
  flex-direction: var(--flex-direction);
  flex-wrap: var(--flex-wrap);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  align-content: var(--align-content);
  gap: var(--gap);
}

.elementor-invisible {
  visibility: hidden;
}

.elementor-align-center {
  text-align: center;
}

.elementor-align-center .elementor-button {
  width: auto;
}

.elementor-align-right {
  text-align: right;
}

.elementor-align-right .elementor-button {
  width: auto;
}

.elementor-align-left {
  text-align: left;
}

.elementor-align-left .elementor-button {
  width: auto;
}

.elementor-align-justify .elementor-button {
  width: 100%;
}

.elementor-custom-embed-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.elementor-custom-embed-play i {
  font-size: 100px;
  color: #fff;
  text-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
}

.elementor-custom-embed-play svg {
  height: 100px;
  width: 100px;
  fill: #fff;
  filter: drop-shadow(1px 0 6px rgba(0, 0, 0, 0.3));
}

.elementor-custom-embed-play i,
.elementor-custom-embed-play svg {
  opacity: 0.8;
  transition: all 0.5s;
}

.elementor-custom-embed-play.elementor-playing i {
  font-family: eicons;
}

.elementor-custom-embed-play.elementor-playing i::before {
  content: "\e8fb";
}

.elementor-custom-embed-play.elementor-playing i,
.elementor-custom-embed-play.elementor-playing svg {
  animation: eicon-spin 2s linear infinite;
}

.elementor-tag {
  display: inline-flex;
}

.elementor-ken-burns {
  transition-property: transform;
  transition-duration: 10s;
  transition-timing-function: linear;
}

.elementor-ken-burns--out {
  transform: scale(1.3);
}

.elementor-ken-burns--active {
  transition-duration: 20s;
}

.elementor-ken-burns--active.elementor-ken-burns--out {
  transform: scale(1);
}

.elementor-ken-burns--active.elementor-ken-burns--in {
  transform: scale(1.3);
}

@media (min-width: 768px) {
  .elementor-widescreen-align-center {
    text-align: center;
  }

  .elementor-widescreen-align-center .elementor-button {
    width: auto;
  }

  .elementor-widescreen-align-right {
    text-align: right;
  }

  .elementor-widescreen-align-right .elementor-button {
    width: auto;
  }

  .elementor-widescreen-align-left {
    text-align: left;
  }

  .elementor-widescreen-align-left .elementor-button {
    width: auto;
  }

  .elementor-widescreen-align-justify .elementor-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .elementor-laptop-align-center {
    text-align: center;
  }

  .elementor-laptop-align-center .elementor-button {
    width: auto;
  }

  .elementor-laptop-align-right {
    text-align: right;
  }

  .elementor-laptop-align-right .elementor-button {
    width: auto;
  }

  .elementor-laptop-align-left {
    text-align: left;
  }

  .elementor-laptop-align-left .elementor-button {
    width: auto;
  }

  .elementor-laptop-align-justify .elementor-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .elementor-tablet_extra-align-center {
    text-align: center;
  }

  .elementor-tablet_extra-align-center .elementor-button {
    width: auto;
  }

  .elementor-tablet_extra-align-right {
    text-align: right;
  }

  .elementor-tablet_extra-align-right .elementor-button {
    width: auto;
  }

  .elementor-tablet_extra-align-left {
    text-align: left;
  }

  .elementor-tablet_extra-align-left .elementor-button {
    width: auto;
  }

  .elementor-tablet_extra-align-justify .elementor-button {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .elementor-tablet-align-center {
    text-align: center;
  }

  .elementor-tablet-align-center .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-right {
    text-align: right;
  }

  .elementor-tablet-align-right .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-left {
    text-align: left;
  }

  .elementor-tablet-align-left .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-justify .elementor-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .elementor-mobile_extra-align-center {
    text-align: center;
  }

  .elementor-mobile_extra-align-center .elementor-button {
    width: auto;
  }

  .elementor-mobile_extra-align-right {
    text-align: right;
  }

  .elementor-mobile_extra-align-right .elementor-button {
    width: auto;
  }

  .elementor-mobile_extra-align-left {
    text-align: left;
  }

  .elementor-mobile_extra-align-left .elementor-button {
    width: auto;
  }

  .elementor-mobile_extra-align-justify .elementor-button {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .elementor-mobile-align-center {
    text-align: center;
  }

  .elementor-mobile-align-center .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-right {
    text-align: right;
  }

  .elementor-mobile-align-right .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-left {
    text-align: left;
  }

  .elementor-mobile-align-left .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-justify .elementor-button {
    width: 100%;
  }
}

:root {
  --page-title-display: block;
}

.elementor-page-title,
h1.entry-title {
  display: var(--page-title-display);
}

@keyframes eicon-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.eicon-animation-spin {
  animation: eicon-spin 2s linear infinite;
}

.elementor-section {
  position: relative;
}

.elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media (max-width: 1024px) {
  .elementor-section .elementor-container {
    flex-wrap: wrap;
  }
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.elementor-section.elementor-section-stretched {
  position: relative;
  width: 100%;
}

.elementor-section.elementor-section-items-top > .elementor-container {
  align-items: flex-start;
}

.elementor-section.elementor-section-items-middle > .elementor-container {
  align-items: center;
}

.elementor-section.elementor-section-items-bottom > .elementor-container {
  align-items: flex-end;
}

@media (min-width: 768px) {
  .elementor-section.elementor-section-height-full {
    height: 100vh;
  }

  .elementor-section.elementor-section-height-full > .elementor-container {
    height: 100%;
  }
}

.elementor-bc-flex-widget
  .elementor-section-content-top
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: flex-start;
}

.elementor-bc-flex-widget
  .elementor-section-content-middle
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: center;
}

.elementor-bc-flex-widget
  .elementor-section-content-bottom
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: flex-end;
}

.elementor-row {
  width: 100%;
  display: flex;
}

@media (max-width: 1024px) {
  .elementor-row {
    flex-wrap: wrap;
  }
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-widget-wrap > .elementor-element {
  width: 100%;
}

.elementor-widget-wrap.e-swiper-container {
  width: calc(
    100% -
      (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px))
  );
}

.elementor-widget {
  position: relative;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-widget:not(:last-child).elementor-absolute,
.elementor-widget:not(:last-child).elementor-widget__width-auto,
.elementor-widget:not(:last-child).elementor-widget__width-initial {
  margin-bottom: 0;
}

.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}

.elementor-column-wrap {
  width: 100%;
  position: relative;
  display: flex;
}

.elementor-column-gap-narrow
  > .elementor-column
  > .elementor-element-populated {
  padding: 5px;
}

.elementor-column-gap-default
  > .elementor-column
  > .elementor-element-populated {
  padding: 10px;
}

.elementor-column-gap-extended
  > .elementor-column
  > .elementor-element-populated {
  padding: 15px;
}

.elementor-column-gap-wide > .elementor-column > .elementor-element-populated {
  padding: 20px;
}

.elementor-column-gap-wider > .elementor-column > .elementor-element-populated {
  padding: 30px;
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
  padding: 0;
}

@media (min-width: 768px) {
  .elementor-column.elementor-col-10,
  .elementor-column[data-col="10"] {
    width: 10%;
  }

  .elementor-column.elementor-col-11,
  .elementor-column[data-col="11"] {
    width: 11.111%;
  }

  .elementor-column.elementor-col-12,
  .elementor-column[data-col="12"] {
    width: 12.5%;
  }

  .elementor-column.elementor-col-14,
  .elementor-column[data-col="14"] {
    width: 14.285%;
  }

  .elementor-column.elementor-col-16,
  .elementor-column[data-col="16"] {
    width: 16.666%;
  }

  .elementor-column.elementor-col-20,
  .elementor-column[data-col="20"] {
    width: 20%;
  }

  .elementor-column.elementor-col-25,
  .elementor-column[data-col="25"] {
    width: 25%;
  }

  .elementor-column.elementor-col-30,
  .elementor-column[data-col="30"] {
    width: 30%;
  }

  .elementor-column.elementor-col-33,
  .elementor-column[data-col="33"] {
    width: 33.333%;
  }

  .elementor-column.elementor-col-40,
  .elementor-column[data-col="40"] {
    width: 40%;
  }

  .elementor-column.elementor-col-50,
  .elementor-column[data-col="50"] {
    width: 50%;
  }

  .elementor-column.elementor-col-60,
  .elementor-column[data-col="60"] {
    width: 60%;
  }

  .elementor-column.elementor-col-66,
  .elementor-column[data-col="66"] {
    width: 66.666%;
  }

  .elementor-column.elementor-col-70,
  .elementor-column[data-col="70"] {
    width: 70%;
  }

  .elementor-column.elementor-col-75,
  .elementor-column[data-col="75"] {
    width: 75%;
  }

  .elementor-column.elementor-col-80,
  .elementor-column[data-col="80"] {
    width: 80%;
  }

  .elementor-column.elementor-col-83,
  .elementor-column[data-col="83"] {
    width: 83.333%;
  }

  .elementor-column.elementor-col-90,
  .elementor-column[data-col="90"] {
    width: 90%;
  }

  .elementor-column.elementor-col-100,
  .elementor-column[data-col="100"] {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .elementor-column.elementor-xs-10 {
    width: 10%;
  }

  .elementor-column.elementor-xs-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-xs-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-xs-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-xs-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-xs-20 {
    width: 20%;
  }

  .elementor-column.elementor-xs-25 {
    width: 25%;
  }

  .elementor-column.elementor-xs-30 {
    width: 30%;
  }

  .elementor-column.elementor-xs-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-xs-40 {
    width: 40%;
  }

  .elementor-column.elementor-xs-50 {
    width: 50%;
  }

  .elementor-column.elementor-xs-60 {
    width: 60%;
  }

  .elementor-column.elementor-xs-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-xs-70 {
    width: 70%;
  }

  .elementor-column.elementor-xs-75 {
    width: 75%;
  }

  .elementor-column.elementor-xs-80 {
    width: 80%;
  }

  .elementor-column.elementor-xs-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-xs-90 {
    width: 90%;
  }

  .elementor-column.elementor-xs-100 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .elementor-column.elementor-sm-10 {
    width: 10%;
  }

  .elementor-column.elementor-sm-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-sm-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-sm-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-sm-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-sm-20 {
    width: 20%;
  }

  .elementor-column.elementor-sm-25 {
    width: 25%;
  }

  .elementor-column.elementor-sm-30 {
    width: 30%;
  }

  .elementor-column.elementor-sm-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-sm-40 {
    width: 40%;
  }

  .elementor-column.elementor-sm-50 {
    width: 50%;
  }

  .elementor-column.elementor-sm-60 {
    width: 60%;
  }

  .elementor-column.elementor-sm-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-sm-70 {
    width: 70%;
  }

  .elementor-column.elementor-sm-75 {
    width: 75%;
  }

  .elementor-column.elementor-sm-80 {
    width: 80%;
  }

  .elementor-column.elementor-sm-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-sm-90 {
    width: 90%;
  }

  .elementor-column.elementor-sm-100 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-column.elementor-md-10 {
    width: 10%;
  }

  .elementor-column.elementor-md-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-md-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-md-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-md-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-md-20 {
    width: 20%;
  }

  .elementor-column.elementor-md-25 {
    width: 25%;
  }

  .elementor-column.elementor-md-30 {
    width: 30%;
  }

  .elementor-column.elementor-md-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-md-40 {
    width: 40%;
  }

  .elementor-column.elementor-md-50 {
    width: 50%;
  }

  .elementor-column.elementor-md-60 {
    width: 60%;
  }

  .elementor-column.elementor-md-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-md-70 {
    width: 70%;
  }

  .elementor-column.elementor-md-75 {
    width: 75%;
  }

  .elementor-column.elementor-md-80 {
    width: 80%;
  }

  .elementor-column.elementor-md-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-md-90 {
    width: 90%;
  }

  .elementor-column.elementor-md-100 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .elementor-reverse-widescreen > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-widescreen > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 1025px) and (max-width: 768px) {
  .elementor-reverse-laptop > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .elementor-reverse-laptop > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 1025px) and (max-width: 768px) {
  .elementor-reverse-laptop > .elementor-container > :first-child,
  .elementor-reverse-laptop > .elementor-container > :nth-child(2),
  .elementor-reverse-laptop > .elementor-container > :nth-child(3),
  .elementor-reverse-laptop > .elementor-container > :nth-child(4),
  .elementor-reverse-laptop > .elementor-container > :nth-child(5),
  .elementor-reverse-laptop > .elementor-container > :nth-child(6),
  .elementor-reverse-laptop > .elementor-container > :nth-child(7),
  .elementor-reverse-laptop > .elementor-container > :nth-child(8),
  .elementor-reverse-laptop > .elementor-container > :nth-child(9),
  .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order: 0;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-tablet_extra > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-reverse-tablet > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-reverse-tablet > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .elementor-reverse-tablet > .elementor-container > :first-child,
  .elementor-reverse-tablet > .elementor-container > :nth-child(2),
  .elementor-reverse-tablet > .elementor-container > :nth-child(3),
  .elementor-reverse-tablet > .elementor-container > :nth-child(4),
  .elementor-reverse-tablet > .elementor-container > :nth-child(5),
  .elementor-reverse-tablet > .elementor-container > :nth-child(6),
  .elementor-reverse-tablet > .elementor-container > :nth-child(7),
  .elementor-reverse-tablet > .elementor-container > :nth-child(8),
  .elementor-reverse-tablet > .elementor-container > :nth-child(9),
  .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    order: 0;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-mobile_extra > .elementor-container > :nth-child(10) {
    order: 1;
  }
}

@media (max-width: 767px) {
  .elementor-reverse-mobile > .elementor-container > :first-child {
    order: 10;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(2) {
    order: 9;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(3) {
    order: 8;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(4) {
    order: 7;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(5) {
    order: 6;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(6) {
    order: 5;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(7) {
    order: 4;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(8) {
    order: 3;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(9) {
    order: 2;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(10) {
    order: 1;
  }

  .elementor-column {
    width: 100%;
  }
}

ul.elementor-icon-list-items.elementor-inline-items {
  display: flex;
  flex-wrap: wrap;
}

ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
  word-break: break-word;
}

.elementor-grid {
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
}

.elementor-grid .elementor-grid-item {
  min-width: 0;
}

.elementor-grid-0 .elementor-grid {
  display: inline-block;
  width: 100%;
  word-spacing: var(--grid-column-gap);
  margin-bottom: calc(-1 * var(--grid-row-gap));
}

.elementor-grid-0 .elementor-grid .elementor-grid-item {
  display: inline-block;
  margin-bottom: var(--grid-row-gap);
  word-break: break-word;
}

.elementor-grid-1 .elementor-grid {
  grid-template-columns: repeat(1, 1fr);
}

.elementor-grid-2 .elementor-grid {
  grid-template-columns: repeat(2, 1fr);
}

.elementor-grid-3 .elementor-grid {
  grid-template-columns: repeat(3, 1fr);
}

.elementor-grid-4 .elementor-grid {
  grid-template-columns: repeat(4, 1fr);
}

.elementor-grid-5 .elementor-grid {
  grid-template-columns: repeat(5, 1fr);
}

.elementor-grid-6 .elementor-grid {
  grid-template-columns: repeat(6, 1fr);
}

.elementor-grid-7 .elementor-grid {
  grid-template-columns: repeat(7, 1fr);
}

.elementor-grid-8 .elementor-grid {
  grid-template-columns: repeat(8, 1fr);
}

.elementor-grid-9 .elementor-grid {
  grid-template-columns: repeat(9, 1fr);
}

.elementor-grid-10 .elementor-grid {
  grid-template-columns: repeat(10, 1fr);
}

.elementor-grid-11 .elementor-grid {
  grid-template-columns: repeat(11, 1fr);
}

.elementor-grid-12 .elementor-grid {
  grid-template-columns: repeat(12, 1fr);
}

@media (min-width: 768px) {
  .elementor-grid-widescreen-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-widescreen-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-widescreen-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-widescreen-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-widescreen-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-widescreen-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-widescreen-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-widescreen-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-widescreen-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-widescreen-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-widescreen-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-widescreen-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-widescreen-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-widescreen-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (max-width: 768px) {
  .elementor-grid-laptop-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-laptop-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-laptop-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-laptop-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-laptop-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-laptop-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-laptop-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-laptop-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-laptop-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-laptop-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-laptop-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-laptop-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-laptop-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-laptop-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (max-width: 768px) {
  .elementor-grid-tablet_extra-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-tablet_extra-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-tablet_extra-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-tablet_extra-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-tablet_extra-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-tablet_extra-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-tablet_extra-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-tablet_extra-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-tablet_extra-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-tablet_extra-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-tablet_extra-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-tablet_extra-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-tablet_extra-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-tablet_extra-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (max-width: 1024px) {
  .elementor-grid-tablet-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-tablet-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-tablet-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-tablet-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-tablet-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-tablet-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-tablet-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-tablet-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-tablet-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-tablet-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-tablet-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-tablet-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-tablet-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-tablet-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (max-width: 768px) {
  .elementor-grid-mobile_extra-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-mobile_extra-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-mobile_extra-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-mobile_extra-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-mobile_extra-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-mobile_extra-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-mobile_extra-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-mobile_extra-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-mobile_extra-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-mobile_extra-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-mobile_extra-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-mobile_extra-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-mobile_extra-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-mobile_extra-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (max-width: 767px) {
  .elementor-grid-mobile-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-mobile-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-mobile-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-mobile-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-mobile-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-mobile-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-mobile-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-mobile-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-mobile-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-mobile-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-mobile-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-mobile-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-mobile-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-mobile-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (min-width: 1025px) {
  #elementor-device-mode::after {
    content: "desktop";
  }
}

@media (min-width: 768px) {
  #elementor-device-mode::after {
    content: "widescreen";
  }
}

@media (max-width: 768px) {
  #elementor-device-mode::after {
    content: "laptop";
    content: "tablet_extra";
  }
}

@media (max-width: 1024px) {
  #elementor-device-mode::after {
    content: "tablet";
  }
}

@media (max-width: 768px) {
  #elementor-device-mode::after {
    content: "mobile_extra";
  }
}

@media (max-width: 767px) {
  #elementor-device-mode::after {
    content: "mobile";
  }
}

.e-con {
  --border-radius: 0;
  --border-block-start-width: 0px;
  --border-inline-end-width: 0px;
  --border-block-end-width: 0px;
  --border-inline-start-width: 0px;
  --border-style: initial;
  --border-color: initial;
  --container-widget-width: 100%;
  --container-widget-height: initial;
  --container-widget-flex-grow: 0;
  --container-widget-align-self: initial;
  --content-width: min(100%, var(--container-max-width, 1140px));
  --width: 100%;
  --min-height: initial;
  --height: auto;
  --text-align: initial;
  --margin-block-start: 0px;
  --margin-inline-end: 0px;
  --margin-block-end: 0px;
  --margin-inline-start: 0px;
  --padding-block-start: var(--container-default-padding-block-start, 10px);
  --padding-inline-end: var(--container-default-padding-inline-end, 10px);
  --padding-block-end: var(--container-default-padding-block-end, 10px);
  --padding-inline-start: var(--container-default-padding-inline-start, 10px);
  --position: relative;
  --z-index: revert;
  --overflow: visible;
  --gap: var(--widgets-spacing, 20px);
  --overlay-mix-blend-mode: initial;
  --overlay-opacity: 1;
  --overlay-transition: 0.3s;
  --e-con-grid-template-columns: repeat(3, 1fr);
  --e-con-grid-template-rows: repeat(2, 1fr);
  position: var(--position);
  width: var(--width);
  min-width: 0;
  min-height: var(--min-height);
  height: var(--height);
  border-radius: var(--border-radius);
  margin-block-start: var(--bc-margin-block-start, var(--margin-block-start));
  margin-inline-end: var(--bc-margin-inline-end, var(--margin-inline-end));
  margin-block-end: var(--bc-margin-block-end, var(--margin-block-end));
  margin-inline-start: var(
    --bc-margin-inline-start,
    var(--margin-inline-start)
  );
  padding-inline-start: var(
    --bc-padding-inline-start,
    var(--padding-inline-start)
  );
  padding-inline-end: var(--bc-padding-inline-end, var(--padding-inline-end));
  z-index: var(--z-index);
  overflow: var(--overflow);
  transition: background var(--background-transition, 0.3s),
    border var(--border-transition, 0.3s),
    box-shadow var(--border-transition, 0.3s),
    transform var(--e-con-transform-transition-duration, 0.4s);
}

.e-con.e-flex {
  --flex-direction: column;
  --flex-basis: auto;
  --flex-grow: 0;
  --flex-shrink: 1;
  flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
}

.e-con-full,
.e-con > .e-con-inner {
  text-align: var(--text-align);
  padding-block-start: var(
    --bc-padding-block-start,
    var(--padding-block-start)
  );
  padding-block-end: var(--bc-padding-block-end, var(--padding-block-end));
}

.e-con-full.e-flex,
.e-con.e-flex > .e-con-inner {
  flex-direction: var(--flex-direction);
}

.e-con,
.e-con > .e-con-inner {
  display: var(--display);
}

.e-con.e-grid {
  --grid-justify-content: start;
  --grid-align-content: start;
  --grid-auto-flow: row;
}

.e-con.e-grid,
.e-con.e-grid > .e-con-inner {
  grid-template-columns: var(--e-con-grid-template-columns);
  grid-template-rows: var(--e-con-grid-template-rows);
  justify-content: var(--grid-justify-content);
  align-content: var(--grid-align-content);
  grid-auto-flow: var(--grid-auto-flow);
}

.e-con-boxed.e-flex {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.e-con-boxed.e-grid {
  justify-items: legacy;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.e-con-boxed {
  text-align: initial;
  gap: initial;
}

.e-con.e-flex > .e-con-inner {
  flex-wrap: var(--flex-wrap);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  align-content: var(--align-content);
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

.e-con.e-grid > .e-con-inner {
  justify-items: var(--justify-items);
  align-items: var(--align-items);
}

.e-con > .e-con-inner {
  gap: var(--gap);
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 100%;
}

:is(.elementor-section-wrap, [data-elementor-id]) > .e-con {
  --margin-inline-end: auto;
  --margin-inline-start: auto;
  max-width: min(100%, var(--width));
}

.e-con .elementor-widget.elementor-widget {
  margin-block-end: 0;
}

.e-con::before,
.e-con > .elementor-background-slideshow::before,
.e-con
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer::before,
:is(.e-con, .e-con > .e-con-inner)
  > .elementor-background-video-container::before {
  content: var(--background-overlay);
  display: block;
  position: absolute;
  mix-blend-mode: var(--overlay-mix-blend-mode);
  opacity: var(--overlay-opacity);
  transition: var(--overlay-transition, 0.3s);
  border-radius: var(--border-radius);
  border-style: var(--border-style);
  border-color: var(--border-color);
  border-block-start-width: var(--border-block-start-width);
  border-inline-end-width: var(--border-inline-end-width);
  border-block-end-width: var(--border-block-end-width);
  border-inline-start-width: var(--border-inline-start-width);
  top: calc(0px - var(--border-block-start-width));
  left: calc(0px - var(--border-inline-start-width));
  width: max(
    100% + var(--border-inline-end-width) + var(--border-inline-start-width),
    100%
  );
  height: max(
    100% + var(--border-block-start-width) + var(--border-block-end-width),
    100%
  );
}

.e-con::before {
  transition: background var(--overlay-transition, 0.3s),
    border-radius var(--border-transition, 0.3s),
    opacity var(--overlay-transition, 0.3s);
}

.e-con > .elementor-background-slideshow,
:is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container {
  border-radius: var(--border-radius);
  border-style: var(--border-style);
  border-color: var(--border-color);
  border-block-start-width: var(--border-block-start-width);
  border-inline-end-width: var(--border-inline-end-width);
  border-block-end-width: var(--border-block-end-width);
  border-inline-start-width: var(--border-inline-start-width);
  top: calc(0px - var(--border-block-start-width));
  left: calc(0px - var(--border-inline-start-width));
  width: max(
    100% + var(--border-inline-end-width) + var(--border-inline-start-width),
    100%
  );
  height: max(
    100% + var(--border-block-start-width) + var(--border-block-end-width),
    100%
  );
}

@media (max-width: 767px) {
  :is(.e-con, .e-con > .e-con-inner)
    > .elementor-background-video-container.elementor-hidden-phone {
    display: none;
  }
}

:is(.e-con, .e-con > .e-con-inner)
  > .elementor-background-video-container::before {
  z-index: 1;
}

:is(.e-con, .e-con > .e-con-inner) > .elementor-background-slideshow::before {
  z-index: 2;
}

.e-con .elementor-widget {
  min-width: 0;
}

.e-con .elementor-widget-empty,
.e-con .elementor-widget-google_maps,
.e-con .elementor-widget-video,
.e-con .elementor-widget.e-widget-swiper {
  width: 100%;
}

.e-con > .e-con-inner > .elementor-widget > .elementor-widget-container,
.e-con > .elementor-widget > .elementor-widget-container {
  height: 100%;
}

.e-con.e-con > .e-con-inner > .elementor-widget,
.elementor.elementor .e-con > .elementor-widget {
  max-width: 100%;
}

@media (max-width: 767px) {
  .e-con.e-flex {
    --width: 100%;
    --flex-wrap: wrap;
  }
}

.e-con {
  --bc-margin-block-start: var(--margin-top);
  --bc-margin-block-end: var(--margin-bottom);
  --bc-margin-inline-start: var(--margin-left);
  --bc-margin-inline-end: var(--margin-right);
  --bc-padding-block-start: var(--padding-top);
  --bc-padding-block-end: var(--padding-bottom);
  --bc-padding-inline-start: var(--padding-left);
  --bc-padding-inline-end: var(--padding-right);
}

[dir="rtl"] .e-con {
  --bc-margin-inline-start: var(--margin-right);
  --bc-margin-inline-end: var(--margin-left);
  --bc-padding-inline-start: var(--padding-right);
  --bc-padding-inline-end: var(--padding-left);
}

.elementor-form-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  .elementor-field-subgroup,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > .elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > input,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > textarea {
  flex-basis: 100%;
  max-width: 100%;
}

.elementor-form-fields-wrapper.elementor-labels-inline
  > .elementor-field-group
  .elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-inline
  > .elementor-field-group
  > input {
  flex-grow: 1;
}

.elementor-field-group {
  flex-wrap: wrap;
  align-items: center;
}

.elementor-field-group.elementor-field-type-submit {
  align-items: flex-end;
}

.elementor-field-group .elementor-field-textual {
  width: 100%;
  max-width: 100%;
  border: 1px solid #69727d;
  background-color: transparent;
  color: #1f2124;
  vertical-align: middle;
  flex-grow: 1;
}

.elementor-field-group .elementor-field-textual:focus {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  outline: 0;
}

.elementor-field-group .elementor-field-textual::-moz-placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementor-field-group .elementor-field-textual::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementor-field-group .elementor-select-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.elementor-field-group .elementor-select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  flex-basis: 100%;
  padding-right: 20px;
}

.elementor-field-group .elementor-select-wrapper::before {
  content: "\e92a";
  font-family: eicons;
  font-size: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.elementor-field-group.elementor-field-type-select-multiple
  .elementor-select-wrapper::before {
  content: "";
}

.elementor-field-subgroup {
  display: flex;
  flex-wrap: wrap;
}

.elementor-field-subgroup .elementor-field-option label {
  display: inline-block;
}

.elementor-field-subgroup.elementor-subgroup-inline .elementor-field-option {
  padding-right: 10px;
}

.elementor-field-subgroup:not(.elementor-subgroup-inline)
  .elementor-field-option {
  flex-basis: 100%;
}

.elementor-field-type-acceptance
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-acceptance
  .elementor-field-subgroup
  .elementor-field-option
  label,
.elementor-field-type-checkbox
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-checkbox
  .elementor-field-subgroup
  .elementor-field-option
  label,
.elementor-field-type-radio
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-radio
  .elementor-field-subgroup
  .elementor-field-option
  label {
  display: inline;
}

.elementor-field-label {
  cursor: pointer;
}

.elementor-mark-required .elementor-field-label::after {
  content: "*";
  color: red;
  padding-left: 0.2em;
}

.elementor-field-textual {
  line-height: 1.4;
  font-size: 15px;
  min-height: 40px;
  padding: 5px 14px;
  border-radius: 3px;
}

.elementor-field-textual.elementor-size-xs {
  font-size: 13px;
  min-height: 33px;
  padding: 4px 12px;
  border-radius: 2px;
}

.elementor-field-textual.elementor-size-md {
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
  border-radius: 4px;
}

.elementor-field-textual.elementor-size-lg {
  font-size: 18px;
  min-height: 59px;
  padding: 7px 20px;
  border-radius: 5px;
}

.elementor-field-textual.elementor-size-xl {
  font-size: 20px;
  min-height: 72px;
  padding: 8px 24px;
  border-radius: 6px;
}

.elementor-button-align-stretch
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button {
  flex-basis: 100%;
}

.elementor-button-align-stretch .e-form__buttons__wrapper {
  flex-basis: 50%;
  flex-grow: 1;
}

.elementor-button-align-stretch .e-form__buttons__wrapper__button {
  flex-basis: 100%;
}

.elementor-button-align-center .e-form__buttons,
.elementor-button-align-center .elementor-field-type-submit {
  justify-content: center;
}

.elementor-button-align-start .e-form__buttons,
.elementor-button-align-start .elementor-field-type-submit {
  justify-content: flex-start;
}

.elementor-button-align-end .e-form__buttons,
.elementor-button-align-end .elementor-field-type-submit {
  justify-content: flex-end;
}

.elementor-button-align-center
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button,
.elementor-button-align-end
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button,
.elementor-button-align-start
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button {
  flex-basis: auto;
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper {
  flex-grow: 0;
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-center .e-form__buttons__wrapper__button,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper__button,
.elementor-button-align-start .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper__button {
  flex-basis: auto;
}

@media screen and (max-width: 1024px) {
  .elementor-tablet-button-align-stretch
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: 100%;
  }

  .elementor-tablet-button-align-stretch .e-form__buttons__wrapper {
    flex-basis: 50%;
    flex-grow: 1;
  }

  .elementor-tablet-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis: 100%;
  }

  .elementor-tablet-button-align-center .e-form__buttons,
  .elementor-tablet-button-align-center .elementor-field-type-submit {
    justify-content: center;
  }

  .elementor-tablet-button-align-start .e-form__buttons,
  .elementor-tablet-button-align-start .elementor-field-type-submit {
    justify-content: flex-start;
  }

  .elementor-tablet-button-align-end .e-form__buttons,
  .elementor-tablet-button-align-end .elementor-field-type-submit {
    justify-content: flex-end;
  }

  .elementor-tablet-button-align-center
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-tablet-button-align-end
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-tablet-button-align-start
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: auto;
  }

  .elementor-tablet-button-align-center .e-form__buttons__wrapper,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper {
    flex-grow: 0;
  }

  .elementor-tablet-button-align-center .e-form__buttons__wrapper,
  .elementor-tablet-button-align-center .e-form__buttons__wrapper__button,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper__button,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper__button {
    flex-basis: auto;
  }
}

@media screen and (max-width: 767px) {
  .elementor-mobile-button-align-stretch
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: 100%;
  }

  .elementor-mobile-button-align-stretch .e-form__buttons__wrapper {
    flex-basis: 50%;
    flex-grow: 1;
  }

  .elementor-mobile-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis: 100%;
  }

  .elementor-mobile-button-align-center .e-form__buttons,
  .elementor-mobile-button-align-center .elementor-field-type-submit {
    justify-content: center;
  }

  .elementor-mobile-button-align-start .e-form__buttons,
  .elementor-mobile-button-align-start .elementor-field-type-submit {
    justify-content: flex-start;
  }

  .elementor-mobile-button-align-end .e-form__buttons,
  .elementor-mobile-button-align-end .elementor-field-type-submit {
    justify-content: flex-end;
  }

  .elementor-mobile-button-align-center
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-mobile-button-align-end
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-mobile-button-align-start
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: auto;
  }

  .elementor-mobile-button-align-center .e-form__buttons__wrapper,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper {
    flex-grow: 0;
  }

  .elementor-mobile-button-align-center .e-form__buttons__wrapper,
  .elementor-mobile-button-align-center .e-form__buttons__wrapper__button,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper__button,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper__button {
    flex-basis: auto;
  }
}

.elementor-error .elementor-field {
  border-color: #d9534f;
}

.elementor-error .help-inline {
  color: #d9534f;
  font-size: 0.9em;
}

.elementor-message {
  margin: 10px 0;
  font-size: 1em;
  line-height: 1;
}

.elementor-message::before {
  content: "\e90e";
  display: inline-block;
  font-family: eicons;
  font-weight: 400;
  font-style: normal;
  vertical-align: middle;
  margin-right: 5px;
}

.elementor-message.elementor-message-danger {
  color: #d9534f;
}

.elementor-message.elementor-message-danger::before {
  content: "\e87f";
}

.elementor-message.form-message-success {
  color: #5cb85c;
}

.elementor-form .elementor-button {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.elementor-form .elementor-button > span {
  display: flex;
  justify-content: center;
}

.elementor-form .elementor-button.elementor-size-xs {
  min-height: 33px;
}

.elementor-form .elementor-button.elementor-size-sm {
  min-height: 40px;
}

.elementor-form .elementor-button.elementor-size-md {
  min-height: 47px;
}

.elementor-form .elementor-button.elementor-size-lg {
  min-height: 59px;
}

.elementor-form .elementor-button.elementor-size-xl {
  min-height: 72px;
}

.elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}

.elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #69727d;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
}

.elementor-button:focus,
.elementor-button:hover,
.elementor-button:visited {
  color: #fff;
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.elementor-button-icon {
  flex-grow: 0;
  order: 5;
}

.elementor-button-icon svg {
  width: 1em;
  height: auto;
}

.elementor-button-icon .e-font-icon-svg {
  height: 1em;
}

.elementor-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

.elementor-button.elementor-size-xs {
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 2px;
}

.elementor-button.elementor-size-md {
  font-size: 16px;
  padding: 15px 30px;
  border-radius: 4px;
}

.elementor-button.elementor-size-lg {
  font-size: 18px;
  padding: 20px 40px;
  border-radius: 5px;
}

.elementor-button.elementor-size-xl {
  font-size: 20px;
  padding: 25px 50px;
  border-radius: 6px;
}

.elementor-button .elementor-align-icon-right {
  margin-left: 5px;
  order: 15;
}

.elementor-button .elementor-align-icon-left {
  margin-right: 5px;
  order: 5;
}

.elementor-button span {
  text-decoration: inherit;
}

.elementor-element.elementor-button-info .elementor-button {
  background-color: #5bc0de;
}

.elementor-element.elementor-button-success .elementor-button {
  background-color: #5cb85c;
}

.elementor-element.elementor-button-warning .elementor-button {
  background-color: #f0ad4e;
}

.elementor-element.elementor-button-danger .elementor-button {
  background-color: #d9534f;
}

.elementor-widget-button .elementor-button .elementor-button-info {
  background-color: #5bc0de;
}

.elementor-widget-button .elementor-button .elementor-button-success {
  background-color: #5cb85c;
}

.elementor-widget-button .elementor-button .elementor-button-warning {
  background-color: #f0ad4e;
}

.elementor-widget-button .elementor-button .elementor-button-danger {
  background-color: #d9534f;
}

.elementor-tab-title a {
  color: inherit;
}

.elementor-view-stacked .elementor-icon {
  padding: 0.5em;
  background-color: #69727d;
  color: #fff;
  fill: #fff;
}

.elementor-view-framed .elementor-icon {
  padding: 0.5em;
  color: #69727d;
  border: 3px solid #69727d;
  background-color: transparent;
}

.elementor-icon {
  display: inline-block;
  line-height: 1;
  transition: all 0.3s;
  color: #69727d;
  font-size: 50px;
  text-align: center;
}

.elementor-icon:hover {
  color: #69727d;
}

.elementor-icon i,
.elementor-icon svg {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}

.elementor-icon i::before,
.elementor-icon svg::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.elementor-icon i.fad {
  width: auto;
}

.elementor-shape-circle .elementor-icon {
  border-radius: 50%;
}

.e-transform .elementor-widget-container {
  transform: perspective(var(--e-transform-perspective, 0))
    rotate(var(--e-transform-rotateZ, 0)) rotateX(var(--e-transform-rotateX, 0))
    rotateY(var(--e-transform-rotateY, 0))
    translate(var(--e-transform-translate, 0))
    translateX(var(--e-transform-translateX, 0))
    translateY(var(--e-transform-translateY, 0))
    scaleX(
      calc(
        var(--e-transform-flipX, 1) *
          var(--e-transform-scaleX, var(--e-transform-scale, 1))
      )
    )
    scaleY(
      calc(
        var(--e-transform-flipY, 1) *
          var(--e-transform-scaleY, var(--e-transform-scale, 1))
      )
    )
    skewX(var(--e-transform-skewX, 0)) skewY(var(--e-transform-skewY, 0));
  transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x);
}

.e-con.e-transform {
  transform: perspective(var(--e-con-transform-perspective, 0))
    rotate(var(--e-con-transform-rotateZ, 0))
    rotateX(var(--e-con-transform-rotateX, 0))
    rotateY(var(--e-con-transform-rotateY, 0))
    translate(var(--e-con-transform-translate, 0))
    translateX(var(--e-con-transform-translateX, 0))
    translateY(var(--e-con-transform-translateY, 0))
    scaleX(
      calc(
        var(--e-con-transform-flipX, 1) *
          var(--e-con-transform-scaleX, var(--e-con-transform-scale, 1))
      )
    )
    scaleY(
      calc(
        var(--e-con-transform-flipY, 1) *
          var(--e-con-transform-scaleY, var(--e-con-transform-scale, 1))
      )
    )
    skewX(var(--e-con-transform-skewX, 0))
    skewY(var(--e-con-transform-skewY, 0));
  transform-origin: var(--e-con-transform-origin-y)
    var(--e-con-transform-origin-x);
}

.elementor-element,
.elementor-lightbox {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

.elementor-element .swiper-container .swiper-slide figure,
.elementor-element .swiper .swiper-slide figure,
.elementor-lightbox .swiper-container .swiper-slide figure,
.elementor-lightbox .swiper .swiper-slide figure {
  line-height: 0;
}

.elementor-element .swiper-container .elementor-lightbox-content-source,
.elementor-element .swiper .elementor-lightbox-content-source,
.elementor-lightbox .swiper-container .elementor-lightbox-content-source,
.elementor-lightbox .swiper .elementor-lightbox-content-source {
  display: none;
}

.elementor-element .swiper-container .elementor-swiper-button,
.elementor-element .swiper-container ~ .elementor-swiper-button,
.elementor-element .swiper .elementor-swiper-button,
.elementor-element .swiper ~ .elementor-swiper-button,
.elementor-lightbox .swiper-container .elementor-swiper-button,
.elementor-lightbox .swiper-container ~ .elementor-swiper-button,
.elementor-lightbox .swiper .elementor-swiper-button,
.elementor-lightbox .swiper ~ .elementor-swiper-button {
  position: absolute;
  display: inline-flex;
  z-index: 1;
  cursor: pointer;
  font-size: 25px;
  color: hsla(0, 0%, 93.3%, 0.9);
  top: 50%;
  transform: translateY(-50%);
}

.elementor-element .swiper-container .elementor-swiper-button svg,
.elementor-element .swiper-container ~ .elementor-swiper-button svg,
.elementor-element .swiper .elementor-swiper-button svg,
.elementor-element .swiper ~ .elementor-swiper-button svg,
.elementor-lightbox .swiper-container .elementor-swiper-button svg,
.elementor-lightbox .swiper-container ~ .elementor-swiper-button svg,
.elementor-lightbox .swiper .elementor-swiper-button svg,
.elementor-lightbox .swiper ~ .elementor-swiper-button svg {
  fill: hsla(0, 0%, 93.3%, 0.9);
  height: 1em;
  width: 1em;
}

.elementor-element .swiper-container .elementor-swiper-button-prev,
.elementor-element .swiper-container ~ .elementor-swiper-button-prev,
.elementor-element .swiper .elementor-swiper-button-prev,
.elementor-element .swiper ~ .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container ~ .elementor-swiper-button-prev,
.elementor-lightbox .swiper .elementor-swiper-button-prev,
.elementor-lightbox .swiper ~ .elementor-swiper-button-prev {
  left: 10px;
}

.elementor-element .swiper-container .elementor-swiper-button-next,
.elementor-element .swiper-container ~ .elementor-swiper-button-next,
.elementor-element .swiper .elementor-swiper-button-next,
.elementor-element .swiper ~ .elementor-swiper-button-next,
.elementor-lightbox .swiper-container .elementor-swiper-button-next,
.elementor-lightbox .swiper-container ~ .elementor-swiper-button-next,
.elementor-lightbox .swiper .elementor-swiper-button-next,
.elementor-lightbox .swiper ~ .elementor-swiper-button-next {
  right: 10px;
}

.elementor-element
  .swiper-container
  .elementor-swiper-button.swiper-button-disabled,
.elementor-element
  .swiper-container
  ~ .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper ~ .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox
  .swiper-container
  .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox
  .swiper-container
  ~ .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper ~ .elementor-swiper-button.swiper-button-disabled {
  opacity: 0.3;
}

.elementor-element
  .swiper-container
  .swiper-image-stretch
  .swiper-slide
  .swiper-slide-image,
.elementor-element
  .swiper
  .swiper-image-stretch
  .swiper-slide
  .swiper-slide-image,
.elementor-lightbox
  .swiper-container
  .swiper-image-stretch
  .swiper-slide
  .swiper-slide-image,
.elementor-lightbox
  .swiper
  .swiper-image-stretch
  .swiper-slide
  .swiper-slide-image {
  width: 100%;
}

.elementor-element
  .swiper-container
  .swiper-horizontal
  > .swiper-pagination-bullets,
.elementor-element
  .swiper-container
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container .swiper-pagination-custom,
.elementor-element .swiper-container .swiper-pagination-fraction,
.elementor-element
  .swiper-container
  ~ .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container ~ .swiper-pagination-custom,
.elementor-element .swiper-container ~ .swiper-pagination-fraction,
.elementor-element .swiper .swiper-horizontal > .swiper-pagination-bullets,
.elementor-element
  .swiper
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper .swiper-pagination-custom,
.elementor-element .swiper .swiper-pagination-fraction,
.elementor-element
  .swiper
  ~ .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper ~ .swiper-pagination-custom,
.elementor-element .swiper ~ .swiper-pagination-fraction,
.elementor-lightbox
  .swiper-container
  .swiper-horizontal
  > .swiper-pagination-bullets,
.elementor-lightbox
  .swiper-container
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container .swiper-pagination-custom,
.elementor-lightbox .swiper-container .swiper-pagination-fraction,
.elementor-lightbox
  .swiper-container
  ~ .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container ~ .swiper-pagination-custom,
.elementor-lightbox .swiper-container ~ .swiper-pagination-fraction,
.elementor-lightbox .swiper .swiper-horizontal > .swiper-pagination-bullets,
.elementor-lightbox
  .swiper
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper .swiper-pagination-custom,
.elementor-lightbox .swiper .swiper-pagination-fraction,
.elementor-lightbox
  .swiper
  ~ .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper ~ .swiper-pagination-custom,
.elementor-lightbox .swiper ~ .swiper-pagination-fraction {
  bottom: 5px;
}

.elementor-element .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-element .swiper-container.swiper-cube ~ .elementor-swiper-button,
.elementor-element .swiper.swiper-cube .elementor-swiper-button,
.elementor-element .swiper.swiper-cube ~ .elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube ~ .elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube ~ .elementor-swiper-button {
  transform: translate3d(0, -50%, 1px);
}

.elementor-element
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-bullets,
.elementor-lightbox
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-bullets {
  bottom: 5px;
  left: 0;
  width: 100%;
}

.elementor-element
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-bullets
  .swiper-pagination-bullet,
.elementor-lightbox
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.elementor-element
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-progressbar,
.elementor-lightbox
  :where(.swiper-container-horizontal)
  ~ .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.elementor-element.elementor-pagination-position-outside .swiper,
.elementor-element.elementor-pagination-position-outside .swiper-container,
.elementor-lightbox.elementor-pagination-position-outside .swiper,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container {
  padding-bottom: 30px;
}

.elementor-element.elementor-pagination-position-outside
  .swiper-container
  .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside
  .swiper-container
  ~ .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside
  .swiper
  .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside
  .swiper
  ~ .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside
  .swiper-container
  .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside
  .swiper-container
  ~ .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside
  .swiper
  .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside
  .swiper
  ~ .elementor-swiper-button {
  top: calc(50% - 30px / 2);
}

.elementor-element .elementor-swiper,
.elementor-lightbox .elementor-swiper {
  position: relative;
}

.elementor-element .elementor-main-swiper,
.elementor-lightbox .elementor-main-swiper {
  position: static;
}

.elementor-element.elementor-arrows-position-outside .swiper,
.elementor-element.elementor-arrows-position-outside .swiper-container,
.elementor-lightbox.elementor-arrows-position-outside .swiper,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container {
  width: calc(100% - 60px);
}

.elementor-element.elementor-arrows-position-outside
  .swiper-container
  .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside
  .swiper-container
  ~ .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside
  .swiper
  .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside
  .swiper
  ~ .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper-container
  .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper-container
  ~ .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper
  .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper
  ~ .elementor-swiper-button-prev {
  left: 0;
}

.elementor-element.elementor-arrows-position-outside
  .swiper-container
  .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside
  .swiper-container
  ~ .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside
  .swiper
  .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside
  .swiper
  ~ .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper-container
  .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper-container
  ~ .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper
  .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside
  .swiper
  ~ .elementor-swiper-button-next {
  right: 0;
}

.elementor-lightbox {
  --lightbox-ui-color: hsla(0, 0%, 93.3%, 0.9);
  --lightbox-ui-color-hover: #fff;
  --lightbox-text-color: var(--lightbox-ui-color);
  --lightbox-header-icons-size: 20px;
  --lightbox-navigation-icons-size: 25px;
}

.elementor-lightbox:not(.elementor-popup-modal) .dialog-header,
.elementor-lightbox:not(.elementor-popup-modal) .dialog-message {
  text-align: center;
}

.elementor-lightbox .dialog-header {
  display: none;
}

.elementor-lightbox .dialog-widget-content {
  background: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}

.elementor-lightbox .dialog-message {
  animation-duration: 0.3s;
  height: 100%;
}

.elementor-lightbox .dialog-message.dialog-lightbox-message {
  padding: 0;
}

.elementor-lightbox .dialog-lightbox-close-button {
  cursor: pointer;
  position: absolute;
  font-size: var(--lightbox-header-icons-size);
  right: 0.75em;
  margin-top: 13px;
  padding: 0.25em;
  z-index: 2;
  line-height: 1;
  display: flex;
}

.elementor-lightbox .dialog-lightbox-close-button svg {
  height: 1em;
  width: 1em;
}

.elementor-lightbox .dialog-lightbox-close-button,
.elementor-lightbox .elementor-swiper-button {
  color: var(--lightbox-ui-color);
  transition: all 0.3s;
  opacity: 1;
}

.elementor-lightbox .dialog-lightbox-close-button svg,
.elementor-lightbox .elementor-swiper-button svg {
  fill: var(--lightbox-ui-color);
}

.elementor-lightbox .dialog-lightbox-close-button:hover,
.elementor-lightbox .elementor-swiper-button:hover {
  color: var(--lightbox-ui-color-hover);
}

.elementor-lightbox .dialog-lightbox-close-button:hover svg,
.elementor-lightbox .elementor-swiper-button:hover svg {
  fill: var(--lightbox-ui-color-hover);
}

.elementor-lightbox .swiper,
.elementor-lightbox .swiper-container {
  height: 100%;
}

.elementor-lightbox .elementor-lightbox-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 70px;
  box-sizing: border-box;
  height: 100%;
  margin: auto;
}

@media (max-width: 767px) {
  .elementor-lightbox .elementor-lightbox-item {
    padding: 70px 0;
  }
}

.elementor-lightbox .elementor-lightbox-image {
  max-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.elementor-lightbox .elementor-lightbox-image,
.elementor-lightbox .elementor-lightbox-image:hover {
  opacity: 1;
  filter: none;
  border: none;
}

.elementor-lightbox .elementor-lightbox-image {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3), 0 0 8px -5px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.elementor-lightbox .elementor-video-container {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape,
.elementor-lightbox .elementor-video-container .elementor-video-portrait,
.elementor-lightbox .elementor-video-container .elementor-video-square {
  width: 100%;
  height: 100%;
  margin: auto;
}

.elementor-lightbox
  .elementor-video-container
  .elementor-video-landscape
  iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe {
  border: 0;
  background-color: #000;
}

.elementor-lightbox
  .elementor-video-container
  .elementor-video-landscape
  iframe,
.elementor-lightbox .elementor-video-container .elementor-video-landscape video,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
  aspect-ratio: var(--video-aspect-ratio, 1.77777);
}

@supports not (aspect-ratio: 1/1) {
  .elementor-lightbox .elementor-video-container .elementor-video-landscape,
  .elementor-lightbox .elementor-video-container .elementor-video-portrait,
  .elementor-lightbox .elementor-video-container .elementor-video-square {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(100% / var(--video-aspect-ratio, 1.77777));
  }

  .elementor-lightbox
    .elementor-video-container
    .elementor-video-landscape
    iframe,
  .elementor-lightbox
    .elementor-video-container
    .elementor-video-landscape
    video,
  .elementor-lightbox
    .elementor-video-container
    .elementor-video-portrait
    iframe,
  .elementor-lightbox
    .elementor-video-container
    .elementor-video-portrait
    video,
  .elementor-lightbox .elementor-video-container .elementor-video-square iframe,
  .elementor-lightbox .elementor-video-container .elementor-video-square video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
  width: min(90vh, 90vw);
  height: min(90vh, 90vw);
}

.elementor-lightbox
  .elementor-video-container
  .elementor-video-landscape
  iframe,
.elementor-lightbox
  .elementor-video-container
  .elementor-video-landscape
  video {
  width: 100%;
  max-height: 90vh;
}

.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video {
  height: 100%;
  max-width: 90vw;
}

@media (min-width: 1025px) {
  .elementor-lightbox .elementor-video-container .elementor-video-landscape {
    width: 85vw;
    max-height: 85vh;
  }

  .elementor-lightbox .elementor-video-container .elementor-video-portrait {
    height: 85vh;
    max-width: 85vw;
  }
}

@media (max-width: 1024px) {
  .elementor-lightbox .elementor-video-container .elementor-video-landscape {
    width: 95vw;
    max-height: 95vh;
  }

  .elementor-lightbox .elementor-video-container .elementor-video-portrait {
    height: 95vh;
    max-width: 95vw;
  }
}

.elementor-lightbox .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox .swiper .elementor-swiper-button-prev {
  left: 0;
}

.elementor-lightbox .swiper-container .elementor-swiper-button-next,
.elementor-lightbox .swiper .elementor-swiper-button-next {
  right: 0;
}

.elementor-lightbox .swiper-container .swiper-pagination-fraction,
.elementor-lightbox .swiper .swiper-pagination-fraction {
  width: -moz-max-content;
  width: max-content;
  color: #fff;
}

.elementor-lightbox .elementor-swiper-button:focus {
  outline-width: 1px;
}

.elementor-lightbox .elementor-swiper-button-next,
.elementor-lightbox .elementor-swiper-button-prev {
  height: 100%;
  display: flex;
  align-items: center;
  width: 15%;
  justify-content: center;
  font-size: var(--lightbox-navigation-icons-size);
}

@media (max-width: 767px) {
  .elementor-lightbox .elementor-swiper-button:focus {
    outline: none;
  }

  .elementor-lightbox .elementor-swiper-button-next,
  .elementor-lightbox .elementor-swiper-button-prev {
    width: 20%;
  }

  .elementor-lightbox .elementor-swiper-button-next i,
  .elementor-lightbox .elementor-swiper-button-prev i {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .elementor-lightbox .elementor-swiper-button-prev {
    left: 0;
    justify-content: flex-start;
  }

  .elementor-lightbox .elementor-swiper-button-next {
    right: 0;
    justify-content: flex-end;
  }
}

.elementor-slideshow__counter {
  color: currentColor;
  font-size: 0.75em;
  width: -moz-max-content;
  width: max-content;
}

.elementor-slideshow__footer,
.elementor-slideshow__header {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  transition: 0.3s;
}

.elementor-slideshow__footer {
  color: var(--lightbox-text-color);
}

.elementor-slideshow__header {
  color: var(--lightbox-ui-color);
  display: flex;
  flex-direction: row-reverse;
  font-size: var(--lightbox-header-icons-size);
  padding-left: 1em;
  padding-right: 2.6em;
  top: 0;
  align-items: center;
  z-index: 10;
}

.elementor-slideshow__header > i,
.elementor-slideshow__header > svg {
  cursor: pointer;
  padding: 0.25em;
  margin: 0 0.35em;
}

.elementor-slideshow__header > i {
  font-size: inherit;
}

.elementor-slideshow__header > i:hover {
  color: var(--lightbox-ui-color-hover);
}

.elementor-slideshow__header > svg {
  box-sizing: content-box;
  fill: var(--lightbox-ui-color);
  height: 1em;
  width: 1em;
}

.elementor-slideshow__header > svg:hover {
  fill: var(--lightbox-ui-color-hover);
}

.elementor-slideshow__header .elementor-slideshow__counter {
  margin-right: auto;
}

.elementor-slideshow__header .elementor-icon-share {
  z-index: 5;
}

.elementor-slideshow__share-menu {
  background-color: transparent;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  transition: background-color 0.4s;
}

.elementor-slideshow__share-menu .elementor-slideshow__share-links a {
  color: #0c0d0e;
}

.elementor-slideshow__share-links {
  display: block;
  position: absolute;
  min-width: 200px;
  right: 2.8em;
  top: 3em;
  background-color: #fff;
  border-radius: 3px;
  padding: 14px 20px;
  transform: scale(0);
  opacity: 0;
  transform-origin: 90% 10%;
  transition: all 0.25s 0.1s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.elementor-slideshow__share-links a {
  text-align: left;
  color: #3f444b;
  font-size: 12px;
  line-height: 2.5;
  display: block;
  opacity: 0;
  transition: opacity 0.5s 0.1s;
}

.elementor-slideshow__share-links a:hover {
  color: #000;
}

.elementor-slideshow__share-links a i,
.elementor-slideshow__share-links a svg {
  margin-right: 0.75em;
}

.elementor-slideshow__share-links a i {
  font-size: 1.25em;
}

.elementor-slideshow__share-links a svg {
  height: 1.25em;
  width: 1.25em;
}

.elementor-slideshow__share-links::before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 0.5em;
  border: 0.45em solid transparent;
  border-bottom-color: #fff;
  transform: translateY(-100%) scaleX(0.7);
}

.elementor-slideshow__footer {
  bottom: 0;
  z-index: 5;
  position: fixed;
}

.elementor-slideshow__description,
.elementor-slideshow__title {
  margin: 0;
}

.elementor-slideshow__title {
  font-size: 16px;
  font-weight: 700;
}

.elementor-slideshow__description {
  font-size: 14px;
}

.elementor-slideshow--ui-hidden .elementor-slideshow__footer,
.elementor-slideshow--ui-hidden .elementor-slideshow__header {
  opacity: 0;
  pointer-events: none;
}

.elementor-slideshow--ui-hidden .elementor-swiper-button-next,
.elementor-slideshow--ui-hidden .elementor-swiper-button-prev {
  opacity: 0;
}

.elementor-slideshow--fullscreen-mode .elementor-video-container {
  width: 100%;
}

.elementor-slideshow--zoom-mode .elementor-slideshow__footer,
.elementor-slideshow--zoom-mode .elementor-slideshow__header {
  background-color: rgba(0, 0, 0, 0.5);
}

.elementor-slideshow--zoom-mode .elementor-swiper-button-next,
.elementor-slideshow--zoom-mode .elementor-swiper-button-prev {
  opacity: 0;
  pointer-events: none;
}

.elementor-slideshow--share-mode .elementor-slideshow__share-menu {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.5);
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links {
  transform: scale(1);
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links,
.elementor-slideshow--share-mode .elementor-slideshow__share-links a {
  opacity: 1;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-twitter {
  color: #1da1f2;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-facebook {
  color: #3b5998;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-pinterest {
  color: #bd081c;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-download-bold {
  color: #9da5ae;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .e-eicon-twitter {
  fill: #1da1f2;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .e-eicon-facebook {
  fill: #3b5998;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .e-eicon-pinterest {
  fill: #bd081c;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .e-eicon-download-bold {
  fill: #9da5ae;
}

.elementor-slideshow--share-mode .eicon-share-arrow {
  z-index: 2;
}

.animated {
  animation-duration: 1.25s;
}

.animated.animated-slow {
  animation-duration: 2s;
}

.animated.animated-fast {
  animation-duration: 0.75s;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.reverse {
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

@media (prefers-reduced-motion: reduce) {
  .animated {
    animation: none;
  }
}

.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.elementor-shape-top {
  top: -1px;
}

.elementor-shape-top:not([data-negative="false"]) svg {
  z-index: -1;
}

.elementor-shape-bottom {
  bottom: -1px;
}

.elementor-shape-bottom:not([data-negative="true"]) svg {
  z-index: -1;
}

.elementor-shape[data-negative="false"].elementor-shape-bottom,
.elementor-shape[data-negative="true"].elementor-shape-top {
  transform: rotate(180deg);
}

.elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

#wp-admin-bar-elementor_edit_page > .ab-item::before {
  content: "\e813";
  font-family: eicons;
  top: 3px;
  font-size: 18px;
}

#wp-admin-bar-elementor_edit_page .ab-submenu .ab-item {
  display: flex;
  width: 200px;
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-type {
  background: #3f444b;
  font-size: 11px;
  line-height: 9px;
  margin-top: 6px;
  padding: 4px 8px;
  border-radius: 3px;
}

#wp-admin-bar-elementor_inspector > .ab-item::before {
  content: "\f348";
  top: 2px;
}

#wpadminbar * {
  font-style: normal;
}

.page-template-elementor_canvas.elementor-page::before {
  display: none;
}

.elementor-post__thumbnail__link {
  transition: none;
}

#left-area ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items,
.elementor .elementor-element ul.elementor-icon-list-items {
  padding: 0;
}

.e--ua-appleWebkit.rtl {
  --flex-right: flex-start;
}

.e--ua-appleWebkit .elementor-share-buttons--align-right,
.e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-right {
  --justify-content: var(--flex-right, flex-end);
}

.e--ua-appleWebkit .elementor-share-buttons--align-center,
.e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-center {
  --justify-content: center;
}

.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-center
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-justify
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-right
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-center
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-right
  .elementor-grid {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--justify-content, space-between);
  margin-left: calc(-0.5 * var(--grid-column-gap));
  margin-right: calc(-0.5 * var(--grid-column-gap));
}

.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-center
  .elementor-grid-item,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-justify
  .elementor-grid-item,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-right
  .elementor-grid-item,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-center
  .elementor-grid-item,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-right
  .elementor-grid-item {
  margin-left: calc(0.5 * var(--grid-column-gap));
  margin-right: calc(0.5 * var(--grid-column-gap));
}

.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-left
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left
  .elementor-grid {
  display: inline-block;
}

.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-left
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-share-buttons--align-left
  .elementor-grid-item,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left
  .elementor-grid,
.e--ua-appleWebkit
  .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left
  .elementor-grid-item {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 1024px) {
  .e--ua-appleWebkit .elementor-share-buttons-tablet--align-right,
  .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-right {
    --justify-content: var(--flex-right, flex-end);
  }

  .e--ua-appleWebkit .elementor-share-buttons-tablet--align-center,
  .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-center {
    --justify-content: center;
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-center
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-justify
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-right
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-center
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-right
    .elementor-grid {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-content, space-between);
    margin-left: calc(-0.5 * var(--grid-column-gap));
    margin-right: calc(-0.5 * var(--grid-column-gap));
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-center
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-justify
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-tablet-right
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-center
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-right
    .elementor-grid-item {
    margin-left: calc(0.5 * var(--grid-column-gap));
    margin-right: calc(0.5 * var(--grid-column-gap));
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-tablet--align-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left
    .elementor-grid {
    display: inline-block;
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-tablet--align-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-tablet--align-left
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left
    .elementor-grid-item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .e--ua-appleWebkit .elementor-share-buttons-mobile--align-right,
  .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-right {
    --justify-content: var(--flex-right, flex-end);
  }

  .e--ua-appleWebkit .elementor-share-buttons-mobile--align-center,
  .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-center {
    --justify-content: center;
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-center
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-justify
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-right
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-center
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-right
    .elementor-grid {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-content, space-between);
    margin-left: calc(-0.5 * var(--grid-column-gap));
    margin-right: calc(-0.5 * var(--grid-column-gap));
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-center
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-justify
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons--align-mobile-right
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-center
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-right
    .elementor-grid-item {
    margin-left: calc(0.5 * var(--grid-column-gap));
    margin-right: calc(0.5 * var(--grid-column-gap));
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-mobile--align-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left
    .elementor-grid {
    display: inline-block;
  }

  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-mobile--align-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-share-buttons-mobile--align-left
    .elementor-grid-item,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left
    .elementor-grid,
  .e--ua-appleWebkit
    .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left
    .elementor-grid-item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .elementor .elementor-hidden-mobile,
  .elementor .elementor-hidden-phone {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .elementor .elementor-hidden-mobile_extra {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elementor .elementor-hidden-tablet {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .elementor .elementor-hidden-laptop,
  .elementor .elementor-hidden-tablet_extra {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 99999px) {
  .elementor .elementor-hidden-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .elementor .elementor-hidden-widescreen {
    display: none;
  }
}

.elementor-widget-text-path {
  font-size: 20px;
  text-align: var(--alignment, left);
}

.elementor-widget-text-path svg {
  width: var(--width);
  max-width: 100%;
  height: auto;
  overflow: visible;
  word-spacing: var(--word-spacing);
  transform: rotate(var(--rotate, 0)) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));
}

.elementor-widget-text-path svg path {
  vector-effect: non-scaling-stroke;
  fill: var(--path-fill, transparent);
  stroke: var(--stroke-color, transparent);
  stroke-width: var(--stroke-width, 1px);
  transition: var(--stroke-transition) stroke, var(--stroke-transition) fill;
}

.elementor-widget-text-path svg:hover path {
  --path-fill: var(--path-fill-hover);
  --stroke-color: var(--stroke-color-hover);
  --stroke-width: var(--stroke-width-hover);
}

.elementor-widget-text-path svg text {
  --fill: var(--text-color);
  fill: var(--fill);
  direction: var(--direction, ltr);
  transition: var(--transition) stroke, var(--transition) stroke-width,
    var(--transition) fill;
}

.elementor-widget-text-path svg text:hover {
  --color: var(--text-color-hover, var(--text-color));
  --fill: var(--color);
  color: var(--color);
}

.elementor-widget-n-tabs {
  --n-tabs-color-accent-fallback: #61ce70;
  --n-tabs-color-secondary-fallback: #54595f;
  --n-tabs-default-padding-block: 15px;
  --n-tabs-default-padding-inline: 35px;
  --n-tabs-background-color: transparent;
  --n-tabs-display: flex;
  --n-tabs-direction: column;
  --n-tabs-gap: 10px;
  --n-tabs-heading-display: flex;
  --n-tabs-heading-direction: row;
  --n-tabs-heading-grow: initial;
  --n-tabs-heading-justify-content: center;
  --n-tabs-heading-width: initial;
  --n-tabs-heading-overflow-x: initial;
  --n-tabs-heading-wrap: nowrap;
  --n-tabs-border-width: 1px;
  --n-tabs-border-color: #d5d8dc;
  --n-tabs-content-display: flex;
  --n-tabs-title-color: var(
    --e-global-color-secondary,
    var(--n-tabs-color-secondary-fallback)
  );
  --n-tabs-title-color-hover: #fff;
  --n-tabs-title-color-active: #fff;
  --n-tabs-title-background-color: #f1f2f3;
  --n-tabs-title-background-color-hover: var(
    --e-global-color-accent,
    var(--n-tabs-color-accent-fallback)
  );
  --n-tabs-title-background-color-active: var(
    --e-global-color-accent,
    var(--n-tabs-color-accent-fallback)
  );
  --n-tabs-title-width: initial;
  --n-tabs-title-height: initial;
  --n-tabs-title-font-size: 1rem;
  --n-tabs-title-white-space: initial;
  --n-tabs-title-justify-content-toggle: initial;
  --n-tabs-title-align-items-toggle: center;
  --n-tabs-title-justify-content: center;
  --n-tabs-title-align-items: center;
  --n-tabs-title-text-align: center;
  --n-tabs-title-direction: row;
  --n-tabs-title-gap: 10px;
  --n-tabs-title-flex-grow: 0;
  --n-tabs-title-flex-basis: content;
  --n-tabs-title-flex-shrink: initial;
  --n-tabs-title-order: initial;
  --n-tabs-title-padding-block-start: var(--n-tabs-default-padding-block);
  --n-tabs-title-padding-inline-end: var(--n-tabs-default-padding-inline);
  --n-tabs-title-padding-block-end: var(--n-tabs-default-padding-block);
  --n-tabs-title-padding-inline-start: var(--n-tabs-default-padding-inline);
  --n-tabs-title-border-radius: initial;
  --n-tabs-title-transition: 0.3s;
  --n-tabs-icon-color: var(
    --e-global-color-secondary,
    var(--n-tabs-color-secondary-fallback)
  );
  --n-tabs-icon-color-hover: var(--n-tabs-title-color-hover);
  --n-tabs-icon-color-active: #fff;
  --n-tabs-icon-gap: 5px;
  width: 100%;
  max-width: 100%;
}

.elementor-widget-n-tabs .e-n-tabs {
  display: var(--n-tabs-display);
  flex-direction: var(--n-tabs-direction);
  gap: var(--n-tabs-gap);
  text-align: start;
  min-width: 0;
}

.elementor-widget-n-tabs .e-n-tabs-heading {
  display: var(--n-tabs-heading-display);
  flex-basis: var(--n-tabs-heading-width);
  flex-direction: var(--n-tabs-heading-direction);
  flex-shrink: 0;
  justify-content: var(--n-tabs-heading-justify-content);
  gap: var(--n-tabs-title-gap);
  overflow-x: var(--n-tabs-heading-overflow-x);
  flex-wrap: var(--n-tabs-heading-wrap);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.elementor-widget-n-tabs .e-n-tabs-heading::-webkit-scrollbar {
  display: none;
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll-active {
  position: relative;
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll-active::before {
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline: -1000vw;
  z-index: 2;
}

.elementor-widget-n-tabs .e-n-tabs-content {
  display: var(--n-tabs-content-display);
  flex-grow: 1;
  min-width: 0;
}

.elementor-widget-n-tabs .e-n-tabs-content > .e-con:not(.e-active) {
  display: none;
}

.elementor-widget-n-tabs
  .e-n-tabs:not(.e-activated)
  > .e-n-tabs-content
  > .e-con:first-child {
  display: flex;
}

.elementor-widget-n-tabs .e-n-tab-title {
  all: unset;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  align-items: var(
    --n-tabs-title-align-items-toggle,
    var(--n-tabs-title-align-items)
  );
  flex-direction: var(--n-tabs-title-direction);
  justify-content: var(
    --n-tabs-title-justify-content-toggle,
    var(--n-tabs-title-justify-content)
  );
  gap: var(--n-tabs-icon-gap);
  border-width: var(--n-tabs-border-width);
  position: relative;
  cursor: pointer;
  outline: none;
  flex-grow: var(--n-tabs-title-flex-grow);
  flex-basis: var(--n-tabs-title-flex-basis);
  flex-shrink: var(--n-tabs-title-flex-shrink);
  padding-block-start: var(--n-tabs-title-padding-block-start);
  padding-inline-end: var(--n-tabs-title-padding-inline-end);
  padding-block-end: var(--n-tabs-title-padding-block-end);
  padding-inline-start: var(--n-tabs-title-padding-inline-start);
  border-radius: var(--n-tabs-title-border-radius);
  height: var(--n-tabs-title-height);
  width: var(--n-tabs-title-width);
  white-space: var(--n-tabs-title-white-space);
  transition: background var(--n-tabs-title-transition),
    color var(--n-tabs-title-transition), border var(--n-tabs-title-transition),
    box-shadow var(--n-tabs-title-transition),
    text-shadow var(--n-tabs-title-transition),
    stroke var(--n-tabs-title-transition),
    stroke-width var(--n-tabs-title-transition),
    -webkit-text-stroke-width var(--n-tabs-title-transition),
    -webkit-text-stroke-color var(--n-tabs-title-transition),
    transform var(--n-tabs-title-transition);
}

.elementor-widget-n-tabs .e-n-tab-title:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
}

.elementor-widget-n-tabs .e-n-tab-title span i,
.elementor-widget-n-tabs .e-n-tab-title span svg {
  transition: color var(--n-tabs-title-transition),
    fill var(--n-tabs-title-transition);
}

.elementor-widget-n-tabs .e-n-tab-title-text {
  display: flex;
  align-items: center;
  font-size: var(--n-tabs-title-font-size);
  text-align: var(--n-tabs-title-text-align);
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  order: var(--n-tabs-icon-order);
  overflow: hidden;
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon i {
  font-size: var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon svg {
  width: var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
  height: var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon:empty {
  display: none;
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected="false"] {
  background-color: var(--n-tabs-title-background-color);
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected="false"],
.elementor-widget-n-tabs .e-n-tab-title[aria-selected="false"] a {
  color: var(--n-tabs-title-color);
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected="false"] .e-n-tab-icon i {
  color: var(--n-tabs-icon-color);
}

.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="false"]
  .e-n-tab-icon
  svg {
  fill: var(--n-tabs-icon-color);
}

.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="false"]
  .e-n-tab-icon
  i:last-child,
.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="false"]
  .e-n-tab-icon
  svg:last-child {
  transform: translateY(-100vh);
  height: 0;
  opacity: 0;
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected="true"],
.elementor-widget-n-tabs .e-n-tab-title[aria-selected="true"] a {
  color: var(--n-tabs-title-color-active);
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected="true"] .e-n-tab-icon i {
  color: var(--n-tabs-icon-color-active);
}

.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"]
  .e-n-tab-icon
  svg {
  fill: var(--n-tabs-icon-color-active);
}

.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"]
  .e-n-tab-icon
  i:first-child,
.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"]
  .e-n-tab-icon
  svg:first-child {
  transform: translateY(-100vh);
  height: 0;
  opacity: 0;
}

.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"][class*="elementor-animation-"]:active,
.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"][class*="elementor-animation-"]:focus,
.elementor-widget-n-tabs
  .e-n-tab-title[aria-selected="true"][class*="elementor-animation-"]:hover {
  transform: none;
  animation: initial;
}

.elementor-widget-n-tabs
  [data-touch-mode="false"]
  .e-n-tab-title[aria-selected="false"]:hover,
.elementor-widget-n-tabs
  [data-touch-mode="false"]
  .e-n-tab-title[aria-selected="false"]:hover
  a {
  color: var(--n-tabs-title-color-hover);
}

.elementor-widget-n-tabs
  [data-touch-mode="false"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  i {
  color: var(--n-tabs-icon-color-hover);
}

.elementor-widget-n-tabs
  [data-touch-mode="false"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  svg {
  fill: var(--n-tabs-icon-color-hover);
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover,
.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  a {
  color: var(--n-tabs-title-color-active);
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  i {
  color: var(--n-tabs-icon-color-active);
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  svg {
  fill: var(--n-tabs-icon-color-active);
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  i:first-child,
.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  svg:first-child {
  transform: translateY(-100vh);
  height: 0;
  opacity: 0;
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover[class*="elementor-animation-"]:active,
.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover[class*="elementor-animation-"]:focus,
.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover[class*="elementor-animation-"]:hover {
  transform: none;
  animation: initial;
}

.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  i:last-child,
.elementor-widget-n-tabs
  [data-touch-mode="true"]
  .e-n-tab-title[aria-selected="false"]:hover
  .e-n-tab-icon
  svg:last-child {
  transform: none;
  height: auto;
  opacity: 1;
}

.elementor
  .elementor-element.elementor-widget-n-tabs
  > .elementor-widget-container
  > .e-n-tabs[data-touch-mode="false"]
  > .e-n-tabs-heading
  .e-n-tab-title[aria-selected="false"]:hover {
  background-color: var(--n-tabs-title-background-color-hover);
  background-image: none;
}

.elementor
  .elementor-element.elementor-widget-n-tabs
  > .elementor-widget-container
  > .e-n-tabs
  > .e-n-tabs-heading
  .e-n-tab-title[aria-selected="true"],
.elementor
  .elementor-element.elementor-widget-n-tabs
  > .elementor-widget-container
  > .e-n-tabs[data-touch-mode="true"]
  > .e-n-tabs-heading
  .e-n-tab-title[aria-selected="false"]:hover {
  background-color: var(--n-tabs-title-background-color-active);
  background-image: none;
}

@media (max-width: 767px) {
  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile {
    --n-tabs-direction: column;
    --n-tabs-heading-display: contents;
    --n-tabs-content-display: contents;
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tabs {
    gap: 0;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile
    .e-n-tabs-content
    > .e-con {
    order: var(--n-tabs-title-order);
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tab-title {
    order: var(--n-tabs-title-order);
    width: auto;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile
    .e-n-tab-title:not(:first-child) {
    margin-block-start: var(--n-tabs-title-gap);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile
    .e-n-tab-title[aria-selected="true"] {
    margin-block-end: var(--n-tabs-gap);
  }
}

@media (max-width: 768px) {
  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra {
    --n-tabs-direction: column;
    --n-tabs-heading-display: contents;
    --n-tabs-content-display: contents;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile_extra
    .e-n-tabs {
    gap: 0;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile_extra
    .e-n-tabs-content
    > .e-con {
    order: var(--n-tabs-title-order);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile_extra
    .e-n-tab-title {
    order: var(--n-tabs-title-order);
    width: auto;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile_extra
    .e-n-tab-title:not(:first-child) {
    margin-block-start: var(--n-tabs-title-gap);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-mobile_extra
    .e-n-tab-title[aria-selected="true"] {
    margin-block-end: var(--n-tabs-gap);
  }
}

@media (max-width: 1024px) {
  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet {
    --n-tabs-direction: column;
    --n-tabs-heading-display: contents;
    --n-tabs-content-display: contents;
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tabs {
    gap: 0;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet
    .e-n-tabs-content
    > .e-con {
    order: var(--n-tabs-title-order);
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tab-title {
    order: var(--n-tabs-title-order);
    width: auto;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet
    .e-n-tab-title:not(:first-child) {
    margin-block-start: var(--n-tabs-title-gap);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet
    .e-n-tab-title[aria-selected="true"] {
    margin-block-end: var(--n-tabs-gap);
  }
}

@media (max-width: 768px) {
  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra {
    --n-tabs-direction: column;
    --n-tabs-heading-display: contents;
    --n-tabs-content-display: contents;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet_extra
    .e-n-tabs {
    gap: 0;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet_extra
    .e-n-tabs-content
    > .e-con {
    order: var(--n-tabs-title-order);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet_extra
    .e-n-tab-title {
    order: var(--n-tabs-title-order);
    width: auto;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet_extra
    .e-n-tab-title:not(:first-child) {
    margin-block-start: var(--n-tabs-title-gap);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-tablet_extra
    .e-n-tab-title[aria-selected="true"] {
    margin-block-end: var(--n-tabs-gap);
  }
}

@media (max-width: 768px) {
  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop {
    --n-tabs-direction: column;
    --n-tabs-heading-display: contents;
    --n-tabs-content-display: contents;
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tabs {
    gap: 0;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-laptop
    .e-n-tabs-content
    > .e-con {
    order: var(--n-tabs-title-order);
  }

  .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tab-title {
    order: var(--n-tabs-title-order);
    width: auto;
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-laptop
    .e-n-tab-title:not(:first-child) {
    margin-block-start: var(--n-tabs-title-gap);
  }

  .elementor.elementor
    .elementor-widget-n-tabs.e-n-tabs-laptop
    .e-n-tab-title[aria-selected="true"] {
    margin-block-end: var(--n-tabs-gap);
  }
}

.elementor-widget-n-accordion {
  --n-accordion-title-font-size: 20px;
  --n-accordion-title-flex-grow: initial;
  --n-accordion-title-justify-content: initial;
  --n-accordion-title-icon-order: -1;
  --n-accordion-border-width: 1px;
  --n-accordion-border-color: #d5d8dc;
  --n-accordion-border-style: solid;
  --n-accordion-item-title-flex-grow: initial;
  --n-accordion-item-title-space-between: 0px;
  --n-accordion-item-title-distance-from-content: 0px;
  --n-accordion-padding: 10px;
  --n-accordion-border-radius: 0px;
  --n-accordion-icon-size: 15px;
  --n-accordion-title-normal-color: #1f2124;
  --n-accordion-title-hover-color: #1f2124;
  --n-accordion-title-active-color: #1f2124;
  --n-accordion-icon-normal-color: var(--n-accordion-title-normal-color);
  --n-accordion-icon-hover-color: var(--n-accordion-title-hover-color);
  --n-accordion-icon-active-color: var(--n-accordion-title-active-color);
  --n-accordion-icon-gap: 0 10px;
  width: 100%;
}

.elementor-widget-n-accordion
  .e-n-accordion
  details
  > summary::-webkit-details-marker {
  display: none;
}

.elementor-widget-n-accordion .e-n-accordion-item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.elementor-widget-n-accordion .e-n-accordion-item:not(:last-child) {
  margin-block-end: var(--n-accordion-item-title-space-between);
}

.elementor-widget-n-accordion .e-n-accordion-item-title {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: var(--n-accordion-padding);
  justify-content: var(--n-accordion-title-justify-content);
  border-radius: var(--n-accordion-border-radius);
  flex-grow: var(--n-menu-title-flex-grow);
  gap: var(--n-accordion-icon-gap);
  color: var(--n-accordion-title-normal-color);
  align-items: center;
  cursor: pointer;
}

.elementor-widget-n-accordion .e-n-accordion-item-title-header {
  display: flex;
}

.elementor-widget-n-accordion .e-n-accordion-item-title-header h1,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h2,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h3,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h4,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h5,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h6,
.elementor-widget-n-accordion .e-n-accordion-item-title-header p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.elementor-widget-n-accordion .e-n-accordion-item-title-text {
  font-size: var(--n-accordion-title-font-size);
  align-items: center;
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: var(--n-accordion-title-icon-order);
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span {
  height: var(--n-accordion-icon-size);
  width: var(--n-accordion-icon-size);
  font-size: var(--n-accordion-icon-size);
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span > i {
  color: var(--n-accordion-icon-normal-color);
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span > svg {
  fill: var(--n-accordion-icon-normal-color);
}

.elementor-widget-n-accordion .e-n-accordion-item-title > span {
  cursor: pointer;
}

.elementor-widget-n-accordion
  .e-n-accordion-item[open]
  .e-n-accordion-item-title {
  margin-block-end: var(--n-accordion-item-title-distance-from-content);
  color: var(--n-accordion-title-active-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item[open]
  .e-n-accordion-item-title-icon
  .e-opened {
  display: flex;
}

.elementor-widget-n-accordion
  .e-n-accordion-item[open]
  .e-n-accordion-item-title-icon
  .e-closed {
  display: none;
}

.elementor-widget-n-accordion
  .e-n-accordion-item[open]
  .e-n-accordion-item-title-icon
  span
  > i {
  color: var(--n-accordion-icon-active-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item[open]
  .e-n-accordion-item-title-icon
  span
  > svg {
  fill: var(--n-accordion-icon-active-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item:not([open]):hover
  .e-n-accordion-item-title {
  color: var(--n-accordion-title-hover-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item:not([open]):hover
  .e-n-accordion-item-title-icon
  span
  > i {
  color: var(--n-accordion-icon-hover-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item:not([open]):hover
  .e-n-accordion-item-title-icon
  span
  > svg {
  fill: var(--n-accordion-icon-hover-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item
  .e-n-accordion-item-title-icon
  .e-opened {
  display: none;
}

.elementor-widget-n-accordion
  .e-n-accordion-item
  .e-n-accordion-item-title-icon
  .e-closed {
  display: flex;
}

.elementor-widget-n-accordion
  .e-n-accordion-item
  .e-n-accordion-item-title-icon
  span
  > svg {
  fill: var(--n-accordion-icon-normal-color);
}

.elementor-widget-n-accordion
  .e-n-accordion-item
  .e-n-accordion-item-title-icon
  span
  > i {
  color: var(--n-accordion-icon-normal-color);
}

.elementor-widget-n-accordion .e-n-accordion-item > span {
  cursor: pointer;
}
.elementor-1067 .elementor-element.elementor-element-22dda4a {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 100px 0px 100px 0px;
  z-index: 1;
}

.elementor-1067
  .elementor-element.elementor-element-cd79f51
  > .elementor-element-populated {
  margin: 0px 40px 0px 0px;
  --e-column-margin-right: 40px;
  --e-column-margin-left: 0px;
  padding: 100px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-28f7959 {
  width: auto;
  max-width: auto;
}

.elementor-1067
  .elementor-element.elementor-element-af326ad
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Montserrat", Sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.elementor-1067
  .elementor-element.elementor-element-af326ad
  > .elementor-widget-container {
  margin: 5px 0px 0px 10px;
}

.elementor-1067 .elementor-element.elementor-element-af326ad {
  width: auto;
  max-width: auto;
}

.elementor-1067
  .elementor-element.elementor-element-2ab7bd9
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 75px;
}

.elementor-1067
  .elementor-element.elementor-element-2ab7bd9
  > .elementor-widget-container {
  margin: 20px 0px 25px 0px;
}

.elementor-1067 .elementor-element.elementor-element-4fa0352 {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 19px;
  line-height: 32px;
}

.elementor-1067
  .elementor-element.elementor-element-4fa0352
  > .elementor-widget-container {
  margin: 0px 0px 50px 0px;
}

.elementor-1067 .elementor-element.elementor-element-3102c94 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-23d123b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-1067 .elementor-element.elementor-element-7158edc .elementor-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  fill: var(--e-global-color-cryptozfree9);
  color: var(--e-global-color-cryptozfree9);
  background-color: var(--e-global-color-cryptozfree1);
  border-radius: 4px 4px 4px 4px;
  padding: 20px 40px 20px 40px;
}

.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button:hover,
.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button:focus {
  color: var(--e-global-color-cryptozfree3);
  background-color: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button:hover
  svg,
.elementor-1067
  .elementor-element.elementor-element-7158edc
  .elementor-button:focus
  svg {
  fill: var(--e-global-color-cryptozfree3);
}

.elementor-1067
  .elementor-element.elementor-element-5211e57
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-8285d47
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 60px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-f4d3f11 {
  text-align: left;
  width: auto;
  max-width: auto;
  z-index: 2;
}

.elementor-1067
  .elementor-element.elementor-element-f4d3f11
  > .elementor-widget-container {
  margin: 100px 350px 0px 0px;
  padding: 15px 15px 15px 15px;
  background-color: #1e2738de;
}

.elementor-1067 .elementor-element.elementor-element-d38a40d img {
  opacity: 1;
}

.elementor-1067
  .elementor-element.elementor-element-d38a40d
  > .elementor-widget-container {
  margin: -140px 0px 0px 315px;
  padding: 20px 20px 20px 20px;
  background-color: #1e2738de;
}

.elementor-1067 .elementor-element.elementor-element-d38a40d {
  width: auto;
  max-width: auto;
  z-index: 2;
}

.elementor-1067
  .elementor-element.elementor-element-ad18adf
  > .elementor-widget-container {
  margin: 50px 330px 0px 0px;
  padding: 20px 20px 20px 20px;
  background-color: #1e2738de;
}

.elementor-1067 .elementor-element.elementor-element-ad18adf {
  width: auto;
  max-width: auto;
  z-index: 2;
}

.elementor-1067 .elementor-element.elementor-element-ddddab2 {
  text-align: center;
}

.elementor-1067
  .elementor-element.elementor-element-ddddab2
  > .elementor-widget-container {
  margin: -580px 110px 0px 110px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-12923f8 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 20px 0px;
  z-index: 2;
}

.elementor-1067
  .elementor-element.elementor-element-b7baae4:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-cryptozfree8);
}

.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}

.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -170px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 80px 75px 80px 75px;
}

.elementor-1067
  .elementor-element.elementor-element-b7baae4
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-6f4dc53 {
  --e-image-carousel-slides-to-show: 5;
}

.elementor-1067 .elementor-element.elementor-element-6f4dc53 .swiper-wrapper {
  display: flex;
  align-items: center;
}

.elementor-1067 .elementor-element.elementor-element-39769cb {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-a03beb3
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-bb2cb4b {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 40px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-a23becf
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-6c13737 {
  text-align: center;
}

.elementor-1067
  .elementor-element.elementor-element-6c13737
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
}

.elementor-1067
  .elementor-element.elementor-element-6c13737
  > .elementor-widget-container {
  margin: 0px 0px 60px 0px;
}

.elementor-1067 .elementor-element.elementor-element-47317fc {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-f1fd6cc:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-cryptozfree8);
}

.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-background-slideshow {
  border-radius: 15px 15px 15px 15px;
}

.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 20px 0px 0px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 0px;
  padding: 35px 35px 35px 35px;
}

.elementor-1067
  .elementor-element.elementor-element-f1fd6cc
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5
  .elementor-icon-box-wrapper {
  text-align: left;
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5.elementor-view-stacked
  .elementor-icon {
  background-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5.elementor-view-framed
  .elementor-icon,
.elementor-1067
  .elementor-element.elementor-element-8f472f5.elementor-view-default
  .elementor-icon {
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  border-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067 .elementor-element.elementor-element-8f472f5 {
  --icon-box-icon-margin: 30px;
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree3);
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-8f472f5
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.elementor-1067
  .elementor-element.elementor-element-8f472f5
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-1067 .elementor-element.elementor-element-0a723f2 .elementor-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  background-color: #00000000;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-0a723f2
  .elementor-button:hover,
.elementor-1067
  .elementor-element.elementor-element-0a723f2
  .elementor-button:focus {
  color: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-0a723f2
  .elementor-button:hover
  svg,
.elementor-1067
  .elementor-element.elementor-element-0a723f2
  .elementor-button:focus
  svg {
  fill: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-0a723f2
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-4277925:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-cryptozfree8);
}

.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-background-slideshow {
  border-radius: 15px 15px 15px 15px;
}

.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 35px 35px 35px 35px;
}

.elementor-1067
  .elementor-element.elementor-element-4277925
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-843823d
  .elementor-icon-box-wrapper {
  text-align: left;
}

.elementor-1067
  .elementor-element.elementor-element-843823d.elementor-view-stacked
  .elementor-icon {
  background-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-843823d.elementor-view-framed
  .elementor-icon,
.elementor-1067
  .elementor-element.elementor-element-843823d.elementor-view-default
  .elementor-icon {
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  border-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067 .elementor-element.elementor-element-843823d {
  --icon-box-icon-margin: 30px;
}

.elementor-1067
  .elementor-element.elementor-element-843823d
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree3);
}

.elementor-1067
  .elementor-element.elementor-element-843823d
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-843823d
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.elementor-1067
  .elementor-element.elementor-element-843823d
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-1067 .elementor-element.elementor-element-aa31b8d .elementor-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  background-color: #00000000;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-aa31b8d
  .elementor-button:hover,
.elementor-1067
  .elementor-element.elementor-element-aa31b8d
  .elementor-button:focus {
  color: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-aa31b8d
  .elementor-button:hover
  svg,
.elementor-1067
  .elementor-element.elementor-element-aa31b8d
  .elementor-button:focus
  svg {
  fill: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-aa31b8d
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-528a753:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-cryptozfree8);
}

.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-background-slideshow {
  border-radius: 15px 15px 15px 15px;
}

.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 35px 35px 35px 35px;
}

.elementor-1067
  .elementor-element.elementor-element-528a753
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-646f85f
  .elementor-icon-box-wrapper {
  text-align: left;
}

.elementor-1067
  .elementor-element.elementor-element-646f85f.elementor-view-stacked
  .elementor-icon {
  background-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-646f85f.elementor-view-framed
  .elementor-icon,
.elementor-1067
  .elementor-element.elementor-element-646f85f.elementor-view-default
  .elementor-icon {
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  border-color: var(--e-global-color-cryptozfree1);
}

.elementor-1067 .elementor-element.elementor-element-646f85f {
  --icon-box-icon-margin: 30px;
}

.elementor-1067
  .elementor-element.elementor-element-646f85f
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree3);
}

.elementor-1067
  .elementor-element.elementor-element-646f85f
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-646f85f
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.elementor-1067
  .elementor-element.elementor-element-646f85f
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-1067 .elementor-element.elementor-element-a607d72 .elementor-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  fill: var(--e-global-color-cryptozfree1);
  color: var(--e-global-color-cryptozfree1);
  background-color: #00000000;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-a607d72
  .elementor-button:hover,
.elementor-1067
  .elementor-element.elementor-element-a607d72
  .elementor-button:focus {
  color: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-a607d72
  .elementor-button:hover
  svg,
.elementor-1067
  .elementor-element.elementor-element-a607d72
  .elementor-button:focus
  svg {
  fill: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-a607d72
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-59494ec {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 80px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-d545d51
  > .elementor-element-populated {
  margin: 0px 40px 0px 0px;
  --e-column-margin-right: 40px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-647be6d
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree6);
  font-family: "Montserrat", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-1067
  .elementor-element.elementor-element-647be6d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-e1e274b
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-e1e274b
  > .elementor-widget-container {
  margin: 20px 00px 25px 0px;
}

.elementor-1067 .elementor-element.elementor-element-b6c7806 {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
}

.elementor-1067
  .elementor-element.elementor-element-b6c7806
  > .elementor-widget-container {
  margin: 0px 0px 30px 0px;
}

.elementor-1067 .elementor-element.elementor-element-b57069c {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-0eb8961
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-1067 .elementor-element.elementor-element-999df64 .elementor-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  fill: var(--e-global-color-cryptozfree9);
  color: var(--e-global-color-cryptozfree9);
  background-color: var(--e-global-color-cryptozfree1);
  border-radius: 4px 4px 4px 4px;
  padding: 20px 40px 20px 40px;
}

.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button:hover,
.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button:focus {
  color: var(--e-global-color-cryptozfree3);
  background-color: var(--e-global-color-cryptozfree2);
}

.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button:hover
  svg,
.elementor-1067
  .elementor-element.elementor-element-999df64
  .elementor-button:focus
  svg {
  fill: var(--e-global-color-cryptozfree3);
}

.elementor-1067
  .elementor-element.elementor-element-5737c58
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-15471e2.elementor-column
  > .elementor-widget-wrap {
  justify-content: flex-end;
}

.elementor-1067
  .elementor-element.elementor-element-15471e2
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-76cbf71
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-86b6a60
  > .elementor-widget-container {
  margin: -250px -50px 0px 0px;
  padding: 20px 20px 20px 20px;
  background-color: #1e2738de;
}

.elementor-1067 .elementor-element.elementor-element-86b6a60 {
  width: auto;
  max-width: auto;
  z-index: 2;
}

.elementor-1067 .elementor-element.elementor-element-0ad1f61 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 100px 0px;
}

.elementor-bc-flex-widget
  .elementor-1067
  .elementor-element.elementor-element-37646e8.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-1067
  .elementor-element.elementor-element-37646e8.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-1067
  .elementor-element.elementor-element-37646e8
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-1067
  .elementor-element.elementor-element-965dbbb.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-1067
  .elementor-element.elementor-element-965dbbb.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-1067
  .elementor-element.elementor-element-965dbbb
  > .elementor-element-populated {
  margin: 0px 0px 0px 40px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 40px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-5edfa16
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-5edfa16
  > .elementor-widget-container {
  margin: 0px 0px 35px 0px;
}

.elementor-1067 .elementor-element.elementor-element-b1690e4 {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
}

.elementor-1067
  .elementor-element.elementor-element-b1690e4
  > .elementor-widget-container {
  margin: 0px 0px 60px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(25px / 2);
  margin-left: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-25px / 2);
  margin-left: calc(-25px / 2);
}

body.rtl
  .elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-25px / 2);
}

body:not(.rtl)
  .elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-icon
  i {
  color: var(--e-global-color-cryptozfree1);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-icon
  svg {
  fill: var(--e-global-color-cryptozfree1);
  transition: fill 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-303789e {
  --e-icon-list-icon-size: 30px;
  --icon-vertical-offset: 0px;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-icon {
  padding-right: 20px;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-item
  > a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree3);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-303789e
  > .elementor-widget-container {
  margin: 0px 0px 30px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(25px / 2);
  margin-left: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-25px / 2);
  margin-left: calc(-25px / 2);
}

body.rtl
  .elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-25px / 2);
}

body:not(.rtl)
  .elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-icon
  i {
  color: var(--e-global-color-cryptozfree1);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-icon
  svg {
  fill: var(--e-global-color-cryptozfree1);
  transition: fill 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-2f851fe {
  --e-icon-list-icon-size: 30px;
  --icon-vertical-offset: 0px;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-icon {
  padding-right: 20px;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-item
  > a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree3);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-2f851fe
  > .elementor-widget-container {
  margin: 0px 0px 30px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(25px / 2);
  margin-left: calc(25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-25px / 2);
  margin-left: calc(-25px / 2);
}

body.rtl
  .elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-25px / 2);
}

body:not(.rtl)
  .elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-25px / 2);
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-icon
  i {
  color: var(--e-global-color-cryptozfree1);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-icon
  svg {
  fill: var(--e-global-color-cryptozfree1);
  transition: fill 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-47798f8 {
  --e-icon-list-icon-size: 30px;
  --icon-vertical-offset: 0px;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-icon {
  padding-right: 20px;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-item
  > a {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree3);
  transition: color 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-47798f8
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-e419548:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1067
  .elementor-element.elementor-element-e419548
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../img/nqsh.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.elementor-1067 .elementor-element.elementor-element-e419548 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 300px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-e419548
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067
  .elementor-element.elementor-element-2888685
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-b83e920 {
  text-align: center;
}

.elementor-1067
  .elementor-element.elementor-element-b83e920
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree6);
  font-family: "Montserrat", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-1067
  .elementor-element.elementor-element-b83e920
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-cfc166f {
  text-align: center;
}

.elementor-1067
  .elementor-element.elementor-element-cfc166f
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-cfc166f
  > .elementor-widget-container {
  margin: 10px 0px 10px 0px;
}

.elementor-1067 .elementor-element.elementor-element-344a916 {
  text-align: center;
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
}

.elementor-1067
  .elementor-element.elementor-element-344a916
  > .elementor-widget-container {
  margin: 0px 0px 30px 0px;
  padding: 0px 300px 0px 300px;
}

.elementor-1067 .elementor-element.elementor-element-3b25661 {
  margin-top: 50px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-4da2e98
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-85c0054
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-85c0054
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-85c0054
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 52px;
  font-weight: 700;
}

.elementor-1067
  .elementor-element.elementor-element-85c0054
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-70a9a51
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-2adc57e
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-2adc57e
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-2adc57e
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 52px;
  font-weight: 700;
}

.elementor-1067
  .elementor-element.elementor-element-2adc57e
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-7d77931
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-8b657cb
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-8b657cb
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-8b657cb
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 52px;
  font-weight: 700;
}

.elementor-1067
  .elementor-element.elementor-element-8b657cb
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}

.elementor-1067
  .elementor-element.elementor-element-3d03e86
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-bd3b156
  .elementor-icon-box-title {
  color: var(--e-global-color-cryptozfree1);
}

.elementor-1067
  .elementor-element.elementor-element-bd3b156
  .elementor-icon-box-title,
.elementor-1067
  .elementor-element.elementor-element-bd3b156
  .elementor-icon-box-title
  a {
  font-family: "Montserrat", Sans-serif;
  font-size: 52px;
  font-weight: 700;
}

.elementor-1067
  .elementor-element.elementor-element-bd3b156
  .elementor-icon-box-description {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}

.elementor-1067 .elementor-element.elementor-element-af7d9c1 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 100px 0px;
  z-index: 1;
}

.elementor-1067
  .elementor-element.elementor-element-08b3da6:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(310deg, #268d94 25%, #417b52 100%);
}

.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-background-slideshow {
  border-radius: 15px 15px 15px 15px;
}

.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-08b3da6
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-08b3da6 {
  z-index: 1;
}

.elementor-1067 .elementor-element.elementor-element-9d2e8a7 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-58f7419
  > .elementor-element-populated {
  margin: 0px 10px 0px 40px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 40px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-adac80d {
  --spacer-size: 200px;
}

.elementor-1067 .elementor-element.elementor-element-deb46a5 {
  text-align: left;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-deb46a5
  .elementor-heading-title {
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-deb46a5
  > .elementor-widget-container {
  margin: 10px 0px 30px 0px;
}

.elementor-1067 .elementor-element.elementor-element-f913a91 {
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-f913a91
  > .elementor-widget-container {
  margin: 0px 0px 50px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-56a3b30
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-56a3b30 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-f11dd58
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-f11dd58 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-4d6efbb
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-4d6efbb {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067 .elementor-element.elementor-element-00a0b37 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-4601b21
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-9d2e120 {
  margin-top: -680px;
  margin-bottom: 50px;
  padding: 100px 0px 100px 0px;
  z-index: 3;
}

.elementor-1067
  .elementor-element.elementor-element-09b3311
  > .elementor-element-populated {
  margin: 0px 10px 0px 40px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 40px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-2302ec7 {
  text-align: left;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-2302ec7
  .elementor-heading-title {
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-2302ec7
  > .elementor-widget-container {
  margin: 10px 0px 30px 0px;
}

.elementor-1067 .elementor-element.elementor-element-277ae01 {
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-277ae01
  > .elementor-widget-container {
  margin: 0px 0px 50px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-baa62d7
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-baa62d7 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067 .elementor-element.elementor-element-c14224b {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-1820108
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-1820108 {
  z-index: 1;
}

.elementor-1067 .elementor-element.elementor-element-07a654b {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 120px 0px 100px 0px;
  z-index: 1;
}

.elementor-1067
  .elementor-element.elementor-element-898dbf3:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(310deg, #268d94 25%, #417b52 100%);
}

.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-element-populated,
.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-background-slideshow {
  border-radius: 15px 15px 15px 15px;
}

.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-898dbf3
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1067 .elementor-element.elementor-element-898dbf3 {
  z-index: 1;
}

.elementor-1067 .elementor-element.elementor-element-77d493f {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-280ab7a
  > .elementor-element-populated {
  margin: 0px 10px 0px 40px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 40px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-3fb028c {
  --spacer-size: 200px;
}

.elementor-1067 .elementor-element.elementor-element-8a1e793 {
  text-align: left;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-8a1e793
  .elementor-heading-title {
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-8a1e793
  > .elementor-widget-container {
  margin: 10px 0px 30px 0px;
}

.elementor-1067 .elementor-element.elementor-element-1c6a895 {
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-1c6a895
  > .elementor-widget-container {
  margin: 0px 0px 50px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-9a08c29
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-9a08c29 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-15d0a9e
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-15d0a9e {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-ef9aa90
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-ef9aa90 {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067 .elementor-element.elementor-element-1f08f9c {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-ebfc443
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-f14db92 {
  margin-top: -680px;
  margin-bottom: 0px;
  padding: 100px 0px 50px 0px;
  z-index: 3;
}

.elementor-1067
  .elementor-element.elementor-element-e574caf
  > .elementor-element-populated {
  margin: 0px 10px 0px 40px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 40px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-7300dec {
  text-align: left;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-7300dec
  .elementor-heading-title {
  color: var(--e-global-color-cryptozfree3);
  font-family: "Michroma", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.elementor-1067
  .elementor-element.elementor-element-7300dec
  > .elementor-widget-container {
  margin: 10px 0px 30px 0px;
}

.elementor-1067 .elementor-element.elementor-element-7d02ffd {
  color: var(--e-global-color-cryptozfree5);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  line-height: 32px;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-7d02ffd
  > .elementor-widget-container {
  margin: 0px 0px 50px 0px;
}

.elementor-1067
  .elementor-element.elementor-element-d0c07fb
  > .elementor-widget-container {
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-1067 .elementor-element.elementor-element-d0c07fb {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067 .elementor-element.elementor-element-74b58fb {
  width: auto;
  max-width: auto;
  z-index: 5;
}

.elementor-1067
  .elementor-element.elementor-element-0214194
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 100px 0px 100px 0px;
}

.elementor-1067 .elementor-element.elementor-element-0214194 {
  z-index: 1;
}

@media (min-width: 768px) {
  .elementor-1067 .elementor-element.elementor-element-23d123b {
    width: 45%;
  }

  .elementor-1067 .elementor-element.elementor-element-5211e57 {
    width: 55%;
  }

  .elementor-1067 .elementor-element.elementor-element-d545d51 {
    width: 46.5%;
  }

  .elementor-1067 .elementor-element.elementor-element-0eb8961 {
    width: 45%;
  }

  .elementor-1067 .elementor-element.elementor-element-5737c58 {
    width: 55%;
  }

  .elementor-1067 .elementor-element.elementor-element-15471e2 {
    width: 53.5%;
  }

  .elementor-1067 .elementor-element.elementor-element-37646e8 {
    width: 40%;
  }

  .elementor-1067 .elementor-element.elementor-element-965dbbb {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .elementor-1067 .elementor-element.elementor-element-22dda4a {
    margin-top: -90px;
    margin-bottom: 0px;
    padding: 100px 20px 0px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-cd79f51
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 60px 0px 60px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8285d47.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-8285d47
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 100px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-f4d3f11
    > .elementor-widget-container {
    margin: 0px 350px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-d38a40d
    > .elementor-widget-container {
    margin: 20px 0px 0px 240px;
  }

  .elementor-1067 .elementor-element.elementor-element-d38a40d {
    width: auto;
    max-width: auto;
  }

  .elementor-1067
    .elementor-element.elementor-element-ad18adf
    > .elementor-widget-container {
    margin: 30px 330px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-ddddab2
    > .elementor-widget-container {
    margin: -700px 140px 0px 140px;
  }

  .elementor-1067 .elementor-element.elementor-element-12923f8 {
    padding: 0px 20px 20px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-b7baae4
    > .elementor-element-populated {
    margin: -80px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 60px 60px 60px 60px;
  }

  .elementor-1067 .elementor-element.elementor-element-6f4dc53 {
    --e-image-carousel-slides-to-show: 4;
  }

  .elementor-1067 .elementor-element.elementor-element-bb2cb4b {
    padding: 60px 20px 60px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-6c13737
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067
    .elementor-element.elementor-element-6c13737
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-f1fd6cc
    > .elementor-element-populated {
    margin: 0px 10px 0px 0px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-wrapper {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-title
    a {
    font-size: 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-4277925
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }

  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-wrapper {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-title
    a {
    font-size: 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-528a753
    > .elementor-element-populated {
    margin: 0px 0px 0px 10px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 10px;
  }

  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-wrapper {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-title
    a {
    font-size: 20px;
  }

  .elementor-1067 .elementor-element.elementor-element-59494ec {
    padding: 60px 20px 60px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-d545d51
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 50px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-647be6d {
    text-align: left;
  }

  .elementor-1067 .elementor-element.elementor-element-e1e274b {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-e1e274b
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067 .elementor-element.elementor-element-b6c7806 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-15471e2.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-76cbf71
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-86b6a60
    > .elementor-widget-container {
    margin: -280px 0px 0px 400px;
  }

  .elementor-1067 .elementor-element.elementor-element-86b6a60 {
    width: auto;
    max-width: auto;
  }

  .elementor-1067 .elementor-element.elementor-element-0ad1f61 {
    padding: 80px 20px 80px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-37646e8
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-965dbbb
    > .elementor-element-populated {
    margin: 0px 0px 60px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-5edfa16 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-5edfa16
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067 .elementor-element.elementor-element-b1690e4 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-b1690e4
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-e419548 {
    margin-top: 40px;
    margin-bottom: 0px;
    padding: 0px 20px 60px 20px;
  }

  .elementor-1067 .elementor-element.elementor-element-b83e920 {
    text-align: left;
  }

  .elementor-1067 .elementor-element.elementor-element-cfc166f {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-cfc166f
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067 .elementor-element.elementor-element-344a916 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-344a916
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-3b25661 {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-85c0054
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-85c0054
    .elementor-icon-box-title
    a {
    font-size: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-85c0054
    .elementor-icon-box-description {
    font-size: 14px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2adc57e
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-2adc57e
    .elementor-icon-box-title
    a {
    font-size: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2adc57e
    .elementor-icon-box-description {
    font-size: 14px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8b657cb
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-8b657cb
    .elementor-icon-box-title
    a {
    font-size: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8b657cb
    .elementor-icon-box-description {
    font-size: 14px;
  }

  .elementor-1067
    .elementor-element.elementor-element-bd3b156
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-bd3b156
    .elementor-icon-box-title
    a {
    font-size: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-bd3b156
    .elementor-icon-box-description {
    font-size: 14px;
  }

  .elementor-1067 .elementor-element.elementor-element-af7d9c1 {
    padding: 80px 20px 80px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-58f7419
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 15px 60px 15px;
  }

  .elementor-1067 .elementor-element.elementor-element-deb46a5 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-deb46a5
    .elementor-heading-title {
    font-size: 32px;
    line-height: 48px;
  }

  .elementor-1067 .elementor-element.elementor-element-f913a91 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-f913a91
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-f4b125a {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-22f1b65
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-56a3b30
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-67ef662
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-f11dd58
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-9d2e120 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 80px 20px 80px 20px;
  }

  .elementor-1067 .elementor-element.elementor-element-2302ec7 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-2302ec7
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067 .elementor-element.elementor-element-277ae01 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-277ae01
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-07a654b {
    padding: 80px 20px 80px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-280ab7a
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 15px 60px 15px;
  }

  .elementor-1067 .elementor-element.elementor-element-8a1e793 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-8a1e793
    .elementor-heading-title {
    font-size: 32px;
    line-height: 48px;
  }

  .elementor-1067 .elementor-element.elementor-element-1c6a895 {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-1c6a895
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-f57050c {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-21fdc79
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-9a08c29
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-7f0ae18
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-15d0a9e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-f14db92 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 80px 20px 80px 20px;
  }

  .elementor-1067 .elementor-element.elementor-element-7300dec {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-7300dec
    .elementor-heading-title {
    font-size: 32px;
  }

  .elementor-1067 .elementor-element.elementor-element-7d02ffd {
    text-align: left;
  }

  .elementor-1067
    .elementor-element.elementor-element-7d02ffd
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }
}

@media (max-width: 767px) {
  .elementor-1067 .elementor-element.elementor-element-22dda4a {
    margin-top: -85px;
    margin-bottom: 0px;
    padding: 100px 15px 0px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-cd79f51
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 40px 0px 40px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-af326ad
    .elementor-heading-title {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2ab7bd9
    .elementor-heading-title {
    font-size: 36px;
    line-height: 52px;
  }

  .elementor-1067 .elementor-element.elementor-element-4fa0352 {
    font-size: 16px;
    line-height: 24px;
  }

  .elementor-1067 .elementor-element.elementor-element-23d123b {
    width: 50%;
  }

  .elementor-1067
    .elementor-element.elementor-element-23d123b
    > .elementor-element-populated {
    margin: 0px 5px 0px 0px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-7158edc
    .elementor-button {
    font-size: 14px;
    padding: 15px 15px 15px 15px;
  }

  .elementor-1067 .elementor-element.elementor-element-5211e57 {
    width: 50%;
  }

  .elementor-1067
    .elementor-element.elementor-element-5211e57
    > .elementor-element-populated {
    margin: 0px 0px 0px 5px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 5px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8285d47
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 80px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-f4d3f11 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-f4d3f11
    > .elementor-widget-container {
    margin: 70px 130px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-d38a40d {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-d38a40d
    > .elementor-widget-container {
    margin: 0px 0px 20px 120px;
  }

  .elementor-1067 .elementor-element.elementor-element-ad18adf {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-ad18adf
    > .elementor-widget-container {
    margin: 20px 130px 0px 20px;
  }

  .elementor-1067
    .elementor-element.elementor-element-ddddab2
    > .elementor-widget-container {
    margin: -162% 0% 0% 0%;
  }

  .elementor-1067 .elementor-element.elementor-element-12923f8 {
    padding: 0px 15px 30px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-b7baae4
    > .elementor-element-populated {
    margin: -135px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 40px 25px 40px 25px;
  }

  .elementor-1067 .elementor-element.elementor-element-6f4dc53 {
    --e-image-carousel-slides-to-show: 2;
  }

  .elementor-1067
    .elementor-element.elementor-element-6f4dc53
    > .elementor-widget-container {
    padding: 0% 0% 0% 0%;
  }

  .elementor-1067 .elementor-element.elementor-element-bb2cb4b {
    padding: 40px 15px 40px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-6c13737
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-6c13737
    > .elementor-widget-container {
    margin: 0px 0px 40px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-f1fd6cc
    > .elementor-element-populated {
    margin: 0px 0px 30px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-title
    a {
    font-size: 22px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8f472f5
    .elementor-icon-box-description {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-0a723f2
    .elementor-button {
    font-size: 18px;
  }

  .elementor-1067
    .elementor-element.elementor-element-4277925
    > .elementor-element-populated {
    margin: 0px 0px 30px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-title
    a {
    font-size: 22px;
  }

  .elementor-1067
    .elementor-element.elementor-element-843823d
    .elementor-icon-box-description {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-aa31b8d
    .elementor-button {
    font-size: 18px;
  }

  .elementor-1067
    .elementor-element.elementor-element-528a753
    > .elementor-element-populated {
    margin: 0px 0px 30px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-title,
  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-title
    a {
    font-size: 22px;
  }

  .elementor-1067
    .elementor-element.elementor-element-646f85f
    .elementor-icon-box-description {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-a607d72
    .elementor-button {
    font-size: 18px;
  }

  .elementor-1067 .elementor-element.elementor-element-59494ec {
    padding: 0px 15px 20px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-d545d51
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 40px 0px 40px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-647be6d {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-647be6d
    .elementor-heading-title {
    font-size: 16px;
  }

  .elementor-1067 .elementor-element.elementor-element-e1e274b {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-e1e274b
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-e1e274b
    > .elementor-widget-container {
    margin: 10px 0px 20px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-b6c7806 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .elementor-1067 .elementor-element.elementor-element-0eb8961 {
    width: 100%;
  }

  .elementor-1067
    .elementor-element.elementor-element-0eb8961
    > .elementor-element-populated {
    margin: 0px 5px 0px 0px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-999df64
    .elementor-button {
    font-size: 15px;
    padding: 15px 15px 15px 15px;
  }

  .elementor-1067 .elementor-element.elementor-element-5737c58 {
    width: 50%;
  }

  .elementor-1067
    .elementor-element.elementor-element-5737c58
    > .elementor-element-populated {
    margin: 0px 0px 0px 5px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 5px;
  }

  .elementor-1067
    .elementor-element.elementor-element-15471e2
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-86b6a60
    > .elementor-widget-container {
    margin: -30px 10px 0px 113px;
  }

  .elementor-1067 .elementor-element.elementor-element-0ad1f61 {
    padding: 40px 15px 40px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-37646e8
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-965dbbb
    > .elementor-element-populated {
    margin: 0px 0px 40px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-5edfa16 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-5edfa16
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-5edfa16
    > .elementor-widget-container {
    margin: 10px 0px 20px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-b1690e4 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(15px / 2);
    margin-left: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2);
  }

  body.rtl
    .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    left: calc(-15px / 2);
  }

  body:not(.rtl)
    .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    right: calc(-15px / 2);
  }

  .elementor-1067 .elementor-element.elementor-element-303789e {
    --e-icon-list-icon-size: 50px;
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-1067
    .elementor-element.elementor-element-303789e
    .elementor-icon-list-item
    > a {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-303789e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(15px / 2);
    margin-left: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2);
  }

  body.rtl
    .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    left: calc(-15px / 2);
  }

  body:not(.rtl)
    .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    right: calc(-15px / 2);
  }

  .elementor-1067 .elementor-element.elementor-element-2f851fe {
    --e-icon-list-icon-size: 50px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    .elementor-icon-list-item
    > a {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2f851fe
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(15px / 2);
    margin-left: calc(15px / 2);
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2);
  }

  body.rtl
    .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    left: calc(-15px / 2);
  }

  body:not(.rtl)
    .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item::after {
    right: calc(-15px / 2);
  }

  .elementor-1067 .elementor-element.elementor-element-47798f8 {
    --e-icon-list-icon-size: 50px;
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-1067
    .elementor-element.elementor-element-47798f8
    .elementor-icon-list-item
    > a {
    font-size: 16px;
  }

  .elementor-1067
    .elementor-element.elementor-element-47798f8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-e419548 {
    padding: 0px 15px 0px 15px;
  }

  .elementor-1067 .elementor-element.elementor-element-b83e920 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-b83e920
    .elementor-heading-title {
    font-size: 16px;
  }

  .elementor-1067 .elementor-element.elementor-element-cfc166f {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-cfc166f
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-cfc166f
    > .elementor-widget-container {
    margin: 10px 0px 20px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-344a916 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .elementor-1067
    .elementor-element.elementor-element-4da2e98
    > .elementor-element-populated {
    margin: 0px 0px 20px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-70a9a51
    > .elementor-element-populated {
    margin: 0px 0px 20px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-7d77931
    > .elementor-element-populated {
    margin: 0px 0px 20px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-3d03e86
    > .elementor-element-populated {
    margin: 0px 0px 20px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-af7d9c1 {
    padding: 40px 15px 40px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-08b3da6.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-58f7419
    > .elementor-element-populated {
    padding: 40px 10px 50px 10px;
  }

  .elementor-1067 .elementor-element.elementor-element-deb46a5 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-deb46a5
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-deb46a5
    > .elementor-widget-container {
    margin: 10px 0px 30px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-f913a91 {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }

  .elementor-1067
    .elementor-element.elementor-element-f913a91
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-22f1b65 {
    width: 50%;
  }

  .elementor-1067 .elementor-element.elementor-element-56a3b30 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .elementor-1067
    .elementor-element.elementor-element-56a3b30
    > .elementor-widget-container {
    margin: 0px 15px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-67ef662 {
    width: 50%;
  }

  .elementor-1067 .elementor-element.elementor-element-f11dd58 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .elementor-1067
    .elementor-element.elementor-element-f11dd58
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-4d6efbb
    > .elementor-widget-container {
    margin: 0px 5px 15px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-4d6efbb {
    width: var(--container-widget-width, 110px);
    max-width: 110px;
    --container-widget-width: 110px;
    --container-widget-flex-grow: 0;
  }

  .elementor-1067
    .elementor-element.elementor-element-00a0b37
    > .elementor-widget-container {
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-00a0b37 {
    width: var(--container-widget-width, 125px);
    max-width: 125px;
    --container-widget-width: 125px;
    --container-widget-flex-grow: 0;
  }

  .elementor-1067 .elementor-element.elementor-element-2302ec7 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-2302ec7
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-2302ec7
    > .elementor-widget-container {
    margin: 10px 0px 30px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-277ae01 {
    text-align: center;
    font-size: 16px;
  }

  .elementor-1067 .elementor-element.elementor-element-07a654b {
    padding: 40px 15px 40px 15px;
  }

  .elementor-1067
    .elementor-element.elementor-element-898dbf3.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-280ab7a
    > .elementor-element-populated {
    padding: 40px 10px 50px 10px;
  }

  .elementor-1067 .elementor-element.elementor-element-8a1e793 {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-8a1e793
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-8a1e793
    > .elementor-widget-container {
    margin: 10px 0px 30px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-1c6a895 {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }

  .elementor-1067
    .elementor-element.elementor-element-1c6a895
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-21fdc79 {
    width: 50%;
  }

  .elementor-1067 .elementor-element.elementor-element-9a08c29 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .elementor-1067
    .elementor-element.elementor-element-9a08c29
    > .elementor-widget-container {
    margin: 0px 15px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-7f0ae18 {
    width: 50%;
  }

  .elementor-1067 .elementor-element.elementor-element-15d0a9e {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .elementor-1067
    .elementor-element.elementor-element-15d0a9e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067
    .elementor-element.elementor-element-ef9aa90
    > .elementor-widget-container {
    margin: 0px 5px 15px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-ef9aa90 {
    width: var(--container-widget-width, 110px);
    max-width: 110px;
    --container-widget-width: 110px;
    --container-widget-flex-grow: 0;
  }

  .elementor-1067
    .elementor-element.elementor-element-1f08f9c
    > .elementor-widget-container {
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-1f08f9c {
    width: var(--container-widget-width, 125px);
    max-width: 125px;
    --container-widget-width: 125px;
    --container-widget-flex-grow: 0;
  }

  .elementor-1067 .elementor-element.elementor-element-7300dec {
    text-align: center;
  }

  .elementor-1067
    .elementor-element.elementor-element-7300dec
    .elementor-heading-title {
    font-size: 22px;
    line-height: 36px;
  }

  .elementor-1067
    .elementor-element.elementor-element-7300dec
    > .elementor-widget-container {
    margin: 10px 0px 30px 0px;
  }

  .elementor-1067 .elementor-element.elementor-element-7d02ffd {
    text-align: center;
    font-size: 16px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-1067 .elementor-element.elementor-element-cd79f51 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-23d123b {
    width: 33%;
  }

  .elementor-1067 .elementor-element.elementor-element-8285d47 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-d545d51 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-0eb8961 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-15471e2 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-37646e8 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-965dbbb {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-58f7419 {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-22f1b65 {
    width: 27%;
  }

  .elementor-1067 .elementor-element.elementor-element-280ab7a {
    width: 100%;
  }

  .elementor-1067 .elementor-element.elementor-element-21fdc79 {
    width: 27%;
  }
}
@keyframes bounce {
  20%,
  53%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes flash {
  50%,
  from,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes tada {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from,
  to {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  11.1%,
  from,
  to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  animation-name: jello;
  transform-origin: center;
}

@keyframes bounceIn {
  20%,
  40%,
  60%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

.elementor-animation-grow {
  transition-duration: 0.3s;
  transition-property: transform;
}

.elementor-animation-grow:active,
.elementor-animation-grow:focus,
.elementor-animation-grow:hover {
  transform: scale(1.1);
}

.elementor-animation-shrink {
  transition-duration: 0.3s;
  transition-property: transform;
}

.elementor-animation-shrink:active,
.elementor-animation-shrink:focus,
.elementor-animation-shrink:hover {
  transform: scale(0.9);
}

@keyframes elementor-animation-pulse {
  25% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.9);
  }
}

.elementor-animation-pulse:active,
.elementor-animation-pulse:focus,
.elementor-animation-pulse:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes elementor-animation-pulse-grow {
  to {
    transform: scale(1.1);
  }
}

.elementor-animation-pulse-grow:active,
.elementor-animation-pulse-grow:focus,
.elementor-animation-pulse-grow:hover {
  animation-name: elementor-animation-pulse-grow;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes elementor-animation-pulse-shrink {
  to {
    transform: scale(0.9);
  }
}

.elementor-animation-pulse-shrink:active,
.elementor-animation-pulse-shrink:focus,
.elementor-animation-pulse-shrink:hover {
  animation-name: elementor-animation-pulse-shrink;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes elementor-animation-push {
  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.elementor-animation-push:active,
.elementor-animation-push:focus,
.elementor-animation-push:hover {
  animation-name: elementor-animation-push;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-pop {
  50% {
    transform: scale(1.2);
  }
}

.elementor-animation-pop:active,
.elementor-animation-pop:focus,
.elementor-animation-pop:hover {
  animation-name: elementor-animation-pop;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.elementor-animation-bounce-in {
  transition-duration: 0.5s;
}

.elementor-animation-bounce-in:active,
.elementor-animation-bounce-in:focus,
.elementor-animation-bounce-in:hover {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.elementor-animation-bounce-out {
  transition-duration: 0.5s;
}

.elementor-animation-bounce-out:active,
.elementor-animation-bounce-out:focus,
.elementor-animation-bounce-out:hover {
  transform: scale(0.8);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.elementor-animation-rotate {
  transition-duration: 0.3s;
  transition-property: transform;
}

.elementor-animation-rotate:active,
.elementor-animation-rotate:focus,
.elementor-animation-rotate:hover {
  transform: rotate(4deg);
}

.elementor-animation-grow-rotate {
  transition-duration: 0.3s;
  transition-property: transform;
}

.elementor-animation-grow-rotate:active,
.elementor-animation-grow-rotate:focus,
.elementor-animation-grow-rotate:hover {
  transform: scale(1.1) rotate(4deg);
}

.elementor-animation-float {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
}

.elementor-animation-float:active,
.elementor-animation-float:focus,
.elementor-animation-float:hover {
  transform: translateY(-8px);
}

.elementor-animation-sink {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
}

.elementor-animation-sink:active,
.elementor-animation-sink:focus,
.elementor-animation-sink:hover {
  transform: translateY(8px);
}

@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(-8px);
  }
}

@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px);
  }
}

.elementor-animation-bob:active,
.elementor-animation-bob:focus,
.elementor-animation-bob:hover {
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}

@keyframes elementor-animation-hang {
  0% {
    transform: translateY(8px);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(8px);
  }
}

@keyframes elementor-animation-hang-sink {
  100% {
    transform: translateY(8px);
  }
}

.elementor-animation-hang:active,
.elementor-animation-hang:focus,
.elementor-animation-hang:hover {
  animation-name: elementor-animation-hang-sink, elementor-animation-hang;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}

.elementor-animation-skew {
  transition-duration: 0.3s;
  transition-property: transform;
}

.elementor-animation-skew:active,
.elementor-animation-skew:focus,
.elementor-animation-skew:hover {
  transform: skew(-10deg);
}

.elementor-animation-skew-forward {
  transition-duration: 0.3s;
  transition-property: transform;
  transform-origin: 0 100%;
}

.elementor-animation-skew-forward:active,
.elementor-animation-skew-forward:focus,
.elementor-animation-skew-forward:hover {
  transform: skew(-10deg);
}

.elementor-animation-skew-backward {
  transition-duration: 0.3s;
  transition-property: transform;
  transform-origin: 0 100%;
}

.elementor-animation-skew-backward:active,
.elementor-animation-skew-backward:focus,
.elementor-animation-skew-backward:hover {
  transform: skew(10deg);
}

@keyframes elementor-animation-wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }

  33.3% {
    transform: translateY(-6px);
  }

  49.95% {
    transform: translateY(4px);
  }

  66.6% {
    transform: translateY(-2px);
  }

  83.25% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}

.elementor-animation-wobble-vertical:active,
.elementor-animation-wobble-vertical:focus,
.elementor-animation-wobble-vertical:hover {
  animation-name: elementor-animation-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-horizontal {
  16.65% {
    transform: translateX(8px);
  }

  33.3% {
    transform: translateX(-6px);
  }

  49.95% {
    transform: translateX(4px);
  }

  66.6% {
    transform: translateX(-2px);
  }

  83.25% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

.elementor-animation-wobble-horizontal:active,
.elementor-animation-wobble-horizontal:focus,
.elementor-animation-wobble-horizontal:hover {
  animation-name: elementor-animation-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-to-bottom-right {
  16.65% {
    transform: translate(8px, 8px);
  }

  33.3% {
    transform: translate(-6px, -6px);
  }

  49.95% {
    transform: translate(4px, 4px);
  }

  66.6% {
    transform: translate(-2px, -2px);
  }

  83.25% {
    transform: translate(1px, 1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.elementor-animation-wobble-to-bottom-right:active,
.elementor-animation-wobble-to-bottom-right:focus,
.elementor-animation-wobble-to-bottom-right:hover {
  animation-name: elementor-animation-wobble-to-bottom-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-to-top-right {
  16.65% {
    transform: translate(8px, -8px);
  }

  33.3% {
    transform: translate(-6px, 6px);
  }

  49.95% {
    transform: translate(4px, -4px);
  }

  66.6% {
    transform: translate(-2px, 2px);
  }

  83.25% {
    transform: translate(1px, -1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.elementor-animation-wobble-to-top-right:active,
.elementor-animation-wobble-to-top-right:focus,
.elementor-animation-wobble-to-top-right:hover {
  animation-name: elementor-animation-wobble-to-top-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-top {
  16.65% {
    transform: skew(-12deg);
  }

  33.3% {
    transform: skew(10deg);
  }

  49.95% {
    transform: skew(-6deg);
  }

  66.6% {
    transform: skew(4deg);
  }

  83.25% {
    transform: skew(-2deg);
  }

  100% {
    transform: skew(0);
  }
}

.elementor-animation-wobble-top {
  transform-origin: 0 100%;
}

.elementor-animation-wobble-top:active,
.elementor-animation-wobble-top:focus,
.elementor-animation-wobble-top:hover {
  animation-name: elementor-animation-wobble-top;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-bottom {
  16.65% {
    transform: skew(-12deg);
  }

  33.3% {
    transform: skew(10deg);
  }

  49.95% {
    transform: skew(-6deg);
  }

  66.6% {
    transform: skew(4deg);
  }

  83.25% {
    transform: skew(-2deg);
  }

  100% {
    transform: skew(0);
  }
}

.elementor-animation-wobble-bottom {
  transform-origin: 100% 0;
}

.elementor-animation-wobble-bottom:active,
.elementor-animation-wobble-bottom:focus,
.elementor-animation-wobble-bottom:hover {
  animation-name: elementor-animation-wobble-bottom;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-wobble-skew {
  16.65% {
    transform: skew(-12deg);
  }

  33.3% {
    transform: skew(10deg);
  }

  49.95% {
    transform: skew(-6deg);
  }

  66.6% {
    transform: skew(4deg);
  }

  83.25% {
    transform: skew(-2deg);
  }

  100% {
    transform: skew(0);
  }
}

.elementor-animation-wobble-skew:active,
.elementor-animation-wobble-skew:focus,
.elementor-animation-wobble-skew:hover {
  animation-name: elementor-animation-wobble-skew;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes elementor-animation-buzz {
  50% {
    transform: translateX(3px) rotate(2deg);
  }

  100% {
    transform: translateX(-3px) rotate(-2deg);
  }
}

.elementor-animation-buzz:active,
.elementor-animation-buzz:focus,
.elementor-animation-buzz:hover {
  animation-name: elementor-animation-buzz;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes elementor-animation-buzz-out {
  10% {
    transform: translateX(3px) rotate(2deg);
  }

  20% {
    transform: translateX(-3px) rotate(-2deg);
  }

  30% {
    transform: translateX(3px) rotate(2deg);
  }

  40% {
    transform: translateX(-3px) rotate(-2deg);
  }

  50% {
    transform: translateX(2px) rotate(1deg);
  }

  60% {
    transform: translateX(-2px) rotate(-1deg);
  }

  70% {
    transform: translateX(2px) rotate(1deg);
  }

  80% {
    transform: translateX(-2px) rotate(-1deg);
  }

  90% {
    transform: translateX(1px) rotate(0);
  }

  100% {
    transform: translateX(-1px) rotate(0);
  }
}

.elementor-animation-buzz-out:active,
.elementor-animation-buzz-out:focus,
.elementor-animation-buzz-out:hover {
  animation-name: elementor-animation-buzz-out;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
