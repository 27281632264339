.wci_overview_widget_div iframe {
  overflow-y: hidden !important;
  width: 100% !important;
  height: 495px !important;
}

.wci_overview_widget_div .footer {
  display: none !important;
  opacity: 0 !important;
}
.line-bgs iframe {
  border: 1px solid gray !important;

  background-color: rgb(0, 0, 0) !important;
  border-radius: 20px !important;
  padding: 1rem !important;
}

.line-bgasasd iframe {
  background: black;
  border-radius: 20px;
  padding: 0 0px;
  border: 1px solid gray !important;
}

.tradingview-widget-container iframe {
  color-scheme: none !important;
}
.if-2 {
  color-scheme: none !important ;
}

.livecoinwatch-widget-1 {
  height: 210px !important;
  border-radius: 20px !important;
  border: 1px solid gray !important;
}
.chsa .cg-footer {
  display: none !important;
}
